import DrawStrategy from './DrawLayerStrategy';
import PenStrategy from './DrawLayerPenStrategy';
import Layer from '../../../domain/layer/Layer';

/**
 * 直線描画処理
 */
export default class DrawLayerLineStrategy extends DrawStrategy {
    private origin!: Layer; // 描画開始初期レイヤー状態
    private startX!: number;
    private startY!: number;

    public constructor(layer: Layer, colorIndex: number) {
        super(layer, colorIndex);
    }

    public drawStart(x: number, y: number) {
        this.origin = this.layer.clone();
        this.startX = x;
        this.startY = y;
    }

    public drawMiddle(x: number, y: number) {
        this.layer.overwriteFrom(this.origin);
        const strategy = new PenStrategy(this.layer, this.colorIndex);
        strategy.drawStart(this.startX, this.startY);
        strategy.drawMiddle(x, y);
    }

    public drawEnd(x: number, y: number) {
        // 不要
    }
}
