

















import { Vue, Component } from 'vue-property-decorator';

@Component
export default class MyPageButton extends Vue {

    private click() {
        this.$router.push('mypage');
    }
}
