import Canvas from '../../../domain/canvas/Canvas';
import Palette from '../../../domain/palette/Palette';

/**
 * 描画処理インターフェース
 */
export default abstract class DrawCanvasStrategy {

    constructor(protected canvas: Canvas) {
    }

    /**
     * 描画処理
     * @param 描画引数
     */
    public abstract draw(...args: any[]): void;

    /**
     * カラー番号とパレットから色情報を取得する
     * @param colorIndex 色番号
     */
    protected resolveColor(colorIndex: number, palette: Palette) {
        const color = palette.getColor(colorIndex);
        if (!color) {
            // 見つからない場合は先頭を返す（ありえないはず）
            return palette.getColor(0);
        } else {
            return color;
        }
    }
}
