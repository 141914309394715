import { AccountState, AccountType } from '@/store/account';
import Api from './Api';
import { default as Auth } from '@/logics/service/Auth';

interface Account { // サーバー側形式
    type: string;
    registeredAt: string;
    updatedAt?: string;
    lastLoginAt?: string;
    settlement?: {
        customerId: string;
        subscriptionId?: string;
    };
}

class AccountApiRepository {
    private api = new Api<Account>('account');

    public async get() {
        const user = await Auth.getUser();
        const data = await this.api.get();
        return new Promise<AccountState>((resolve) => {
            resolve({
                type: data.type === AccountType.Premium
                    ? AccountType.Premium : AccountType.Free,
                email: user!.email,
                settlement: data.settlement,
            });
        });
    }

    /**
     * 作成時は認証情報をもとに生成する
     * @param info { id: 識別子, registeredAt: 登録日時 }
     */
    public async create(info: { registeredAt: string }) {
        return this.api.post({
            type: AccountType.Free,
            registeredAt: info.registeredAt,
            lastLoginAt: info.registeredAt,
        });
    }

    public async update(account: AccountState) {
        const user = await Auth.getUser();
        // メールアドレス変更の場合はFirebaseを変更する
        if (user!.email !== account.email) {
            Auth.updateEmail(account.email);
        }
        return this.api.post({
            type: account.type,
            registeredAt: user!.creationTime,
            lastLoginAt: user!.lastSignInTime,
        });
    }

    /**
     * 退会
     */
    public async delete() {
        this.api.delete();
    }
}
export default new AccountApiRepository();
