















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { StrategyType } from '@/logics/service/layer/strategy/DrawLayerStrategyFactory';
import SquareIconButton from '@/components/molecules/SquareIconButton.vue';

@Component({
    components: {
        SquareIconButton,
    },
})
export default class ToolButton extends Vue {
    // 継承先でタイプを指定
    protected type?: StrategyType;
    // 継承先でアイコンパスを指定
    protected iconSrc: string = '';
    protected tooltip: string = '';

    protected onclick() {
        this.$store.commit('editor/selectTool', this.type);
    }

    @Watch('$store.state.editor.activeToolType')
    private getButtonColor() {
        return this.$store.state.editor.activeToolType === this.type ? '#b9ffc8' : undefined;
    }
}
