




















































import { Vue, Component, Prop } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';

@Component({
    components: {
        CircleIconButton,
    },
})
export default class LayerHoverMenu extends Vue {
    @Prop({ required: true }) private draw!: boolean;
    @Prop({ required: true }) private hideclose!: boolean;
    @Prop({ required: true }) private hidefront!: boolean;
    @Prop({ required: true }) private hideback!: boolean;
    private srcFront = require('@/assets/layer/arrow-up.svg');
    private srcBack = require('@/assets/layer/arrow-down.svg');
    private srcClose = require('@/assets/layer/close.svg');
    private srcCopy = require('@/assets/layer/copy.svg');
    private srcToHide = require('@/assets/layer/hide-layer.svg');
    private srcToShow = require('@/assets/layer/show-layer.svg');
    private toHideTooltip = false;
    private toShowTooltip = false;

    private onclickBody() {
        this.$emit('body');
    }

    private onclickFront(e: MouseEvent) {
        this.$emit('front');
    }

    private onclickBack(e: MouseEvent) {
        this.$emit('back');
    }

    private onclickCopy(e: MouseEvent) {
        this.$emit('copy');
    }

    private onclickClose(e: MouseEvent) {
        this.$emit('close');
    }

    private onclickToHide(e: MouseEvent) {
        this.toHideTooltip = false;
        this.$emit('to-hide');
    }

    private onclickToShow(e: MouseEvent) {
        this.toShowTooltip = false;
        this.$emit('to-show');
    }
}
