


































import { Vue, Component, Prop } from 'vue-property-decorator';
import PaletteBoardPreview from '@/components/templates/PaletteBoardPreview.vue';
import Palette from '@/logics/domain/palette/Palette';
import PaletteApiRepository from '@/logics/repository/api/PaletteApiRepository';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import Auth from '@/logics/service/Auth';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';

export interface FormResult {
    palette: Palette;
}

@Component({
    components: {
        PaletteBoardPreview,
        CircleIconButton,
    },
})
export default class LoadPaletteForm extends Vue {
    private srcRemove = require('@/assets/layer/close.svg');

    private items: Palette[] = [];
    private selectIndex = 0;

    private get selectItem() {
        return this.items[this.selectIndex];
    }

    private async mounted() {
        try {
            this.$store.dispatch('startLoading');
            const user = await Auth.getUser();
            this.items = await PaletteApiRepository.search({
                accountId: user!.id,
                keyword: '',
            });
            this.selectIndex = 0;
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }

    private remove(index: number) {
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('form.loadPaletteForm.removeConfirm.title').toString(),
            message: this.$t('form.loadPaletteForm.removeConfirm.message').toString(),
            okCallback: async () => {
                const palette = this.items[index];
                try {
                    this.$store.dispatch('startLoading');
                    if (palette.id) {
                        await PaletteApiRepository.delete(palette.id);
                    }
                    this.items.splice(index, 1);
                } finally {
                    this.$store.dispatch('stopLoading');
                }
            },
        }));
    }

    private cancel() {
        this.$emit('cancel');
    }

    private submit() {
        this.$emit('submit', { palette: this.selectItem });
    }
}
