import DrawStrategy from './DrawLayerStrategy';
import Layer from '../../../domain/layer/Layer';

/**
 * ペン描画処理
 */
export default class DrawLayerPenStrategy extends DrawStrategy {
    private beforeX!: number;
    private beforeY!: number;

    public constructor(layer: Layer, colorIndex: number) {
        super(layer, colorIndex);
    }

    public drawStart(x: number, y: number) {
        this.layer.set(x, y, this.colorIndex);
        this.beforeX = x;
        this.beforeY = y;
    }

    public drawMiddle(x: number, y: number) {
        const dx = x - this.beforeX;
        const dy = y - this.beforeY;
        const increment = Math.abs(dx) > Math.abs(dy) ? 1 : Math.abs(dx / dy);
        if (dx > 0) {
            for (let posX = this.beforeX; posX < x; posX += increment) {
                const posY = Math.round(this.linearInterpolation(this.beforeX, this.beforeY, x, y, posX));
                this.layer.set(Math.round(posX), posY, this.colorIndex);
            }
        } else if (dx < 0) {
            for (let posX = this.beforeX; posX > x; posX -= increment) {
                const posY = Math.round(this.linearInterpolation(this.beforeX, this.beforeY, x, y, posX));
                this.layer.set(Math.round(posX), posY, this.colorIndex);
            }
        } else if (dy > 0) { // 同一X座標時の下方向縦直線
            for (let posY = this.beforeY; posY < y; posY++) {
                this.layer.set(x, posY, this.colorIndex);
            }
        } else if (dy < 0) { // 同一X座標時の上方向縦直線
            for (let posY = this.beforeY; posY > y; posY--) {
                this.layer.set(x, posY, this.colorIndex);
            }
        }
        this.layer.set(x, y, this.colorIndex);

        this.beforeX = x;
        this.beforeY = y;
    }

    public drawEnd(x: number, y: number) {
        this.drawMiddle(x, y);
    }

    /**
     * 線形補完によりY座標を求める
     * @param x1 始点X
     * @param y1 始点Y
     * @param x2 終点X
     * @param y2 終点Y
     * @param x Y座標を求めたい中間点X
     */
    private linearInterpolation(x1: number, y1: number, x2: number, y2: number, x: number) {
        return y1 + (y2 - y1) * (x - x1) / (x2 - x1);
    }
}
