































import { Vue, Component, Prop } from 'vue-property-decorator';
import PixelCanvas from '@/components/organisms/editor/PixelCanvas.vue';
import OutputImageButton from '@/components/organisms/editor/OutputImageButton.vue';
import ToggleGridButton from '@/components/organisms/editor/ToggleGridButton.vue';
import SettingAuxiliaryGridButton from '@/components/organisms/editor/SettingAuxiliaryGridButton.vue';
import RedoButton from '@/components/organisms/editor/RedoButton.vue';
import UndoButton from '@/components/organisms/editor/UndoButton.vue';
import SaveButton from '@/components/organisms/editor/SaveButton.vue';
import SettingButton from '@/components/organisms/editor/SettingButton.vue';
import ToggleSyncButton from '@/components/organisms/editor/ToggleSyncButton.vue';

@Component({
    components: {
        PixelCanvas,
        OutputImageButton,
        ToggleGridButton,
        SettingAuxiliaryGridButton,
        RedoButton,
        UndoButton,
        SaveButton,
        SettingButton,
        ToggleSyncButton,
    },
})
export default class EditorBoard extends Vue {
    private scale: number = 8;
    private realWidth = this.$store.state.editor.width;
    private realHeight = this.$store.state.editor.height;

    private changeScale(delta: number) {
        this.scale = Math.max(8, Math.min(this.scale + delta, 32));
    }
}
