























import { Vue, Component, Prop } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import { default as SettingCanvasForm, FormResult as SettingParams } from '@/components/organisms/form/SettingCanvasForm.vue';

@Component({
    components: {
        CircleIconButton,
        SettingCanvasForm,
    },
})
export default class SettingButton extends Vue {
    private showSettingDialog = false;
    private src: string = require('@/assets/editor/setting.svg');
    private backColor = '#e0e0e0';

    private get name() {
        return this.$store.state.editor.name;
    }

    private get width() {
        return this.$store.state.editor.width;
    }

    private get height() {
        return this.$store.state.editor.height;
    }

    // キャッシュ防止のためgetterは使わない
    private getTagIds() {
        return this.$store.state.editor.additional.tags || [];
    }

    private onmouseover() {
        this.backColor = '#b9ffc8';
    }

    private onmouseleave() {
        this.backColor = '#e0e0e0';
    }

    private async onsubmit(params: SettingParams) {
        this.$store.dispatch('editor/resizeLayer', {
            width: params.width,
            height: params.height,
        });
        this.$store.commit('editor/changeState', {
            name: params.name,
            width: params.width,
            height: params.height,
            tagIds: params.tagIds,
        });
        this.showSettingDialog = false;
    }
}
