











import { Vue, Component } from 'vue-property-decorator';
import AccountButton from '@/components/organisms/account/AccountButton.vue';
import MyPageButton from '@/components/organisms/header/MyPageButton.vue';
import InquiryButton from '@/components/organisms/header/InquiryButton.vue';

@Component({
    components: {
        AccountButton,
        MyPageButton,
        InquiryButton,
    },
})
export default class HeaderBarView extends Vue {
}
