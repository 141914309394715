




















import { Vue, Component, Watch } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';

@Component({
    components: {
        CircleIconButton,
    },
})
export default class SettingAuxiliaryGridButton extends Vue {
    private src: string = require('@/assets/editor/auxiliary-grid.svg');

    private readonly GridSizese = [
        0,
        8,
        16,
        24,
        32,
        48,
        56,
        64,
    ];

    private get auxiliaryGridSize() {
        return this.$store.state.editor.auxiliaryGridSize;
    }

    @Watch('$store.state.editor.auxiliaryGridSize')
    private backColor() {
        if (this.auxiliaryGridSize) {
            return '#b9ffc8';
        } else {
            return '#e0e0e0';
        }
    }

    private changeAuxiliaryGridSize(size: number) {
        this.$store.commit('editor/changeAuxiliaryGridSize', size);
    }
}
