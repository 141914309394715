import DrawLayerStrategy from './DrawLayerStrategy';
import DrawLayerPenStrategy from './DrawLayerPenStrategy';
import { default as DrawLayerSyringeStrategy, StrategyObserver as SyringeObserver } from './DrawLayerSyringeStrategy';
import DrawLayerLineStrategy from './DrawLayerLineStrategy';
import DrawLayerEraserStrategy from './DrawLayerEraserStrategy';
import DrawLayerBucketStrategy from './DrawLayerBucketStrategy';
import { default as DrawLayerSelectRegionStrategy, StrategyObserver as SelectObserver } from './DrawLayerSelectRegionStrategy';
import DrawLayerSquareStrategy from './DrawLayerSquareStrategy';
import DrawLayerSquareStrokedStrategy from './DrawLayerSquareStrokedStrategy';
import DrawLayerCircleStrategy from './DrawLayerCircleStrategy';
import DrawLayerCircleStrokedStrategy from './DrawLayerCircleStrokedStrategy';
import Layer from '../../../domain/layer/Layer';

export type StrategyObserver = SyringeObserver | SelectObserver;

export const enum StrategyType {
    Eraser = 'Eraser',
    Pen = 'Pen',
    Line = 'Line',
    Circle = 'Circle',
    CircleStroked = 'CircleStroked',
    Square = 'Square',
    SquareStroked = 'SquareStroked',
    Syringe = 'Syringe',
    Select = 'Select',
    Bucket = 'Bucket',
}

class DrawLayerStrategyFactory {

    public create(type: StrategyType, layer: Layer, colorIndex: number, observer: StrategyObserver): DrawLayerStrategy {
        switch (type) {
            case StrategyType.Pen:
                return new DrawLayerPenStrategy(layer, colorIndex);
            case StrategyType.Line:
                return new DrawLayerLineStrategy(layer, colorIndex);
            case StrategyType.Eraser:
                return new DrawLayerEraserStrategy(layer);
            case StrategyType.Bucket:
                return new DrawLayerBucketStrategy(layer, colorIndex);
            case StrategyType.Select:
                return new DrawLayerSelectRegionStrategy(layer, observer as SelectObserver);
            case StrategyType.Syringe:
                return new DrawLayerSyringeStrategy(layer, observer as SyringeObserver);
            case StrategyType.Square:
                return new DrawLayerSquareStrategy(layer, colorIndex);
            case StrategyType.SquareStroked:
                return new DrawLayerSquareStrokedStrategy(layer, colorIndex);
            case StrategyType.Circle:
                return new DrawLayerCircleStrategy(layer, colorIndex);
            case StrategyType.CircleStroked:
                return new DrawLayerCircleStrokedStrategy(layer, colorIndex);
            default:
                throw new Error(); // 来ないはず
        }
    }
}

export default new DrawLayerStrategyFactory();
