



import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ToolButton extends Vue {
    @Prop({ required: true }) private src!: string;
    @Prop({ required: true }) private width!: number;
    @Prop({ required: true }) private height!: number;

    private onclick(e: Event) {
        this.$emit('click', e);
    }
}
