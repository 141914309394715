





import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import LayerList from '@/components/organisms/layer/LayerList.vue';

@Component({
    components: {
        LayerList,
    },
})
export default class LayerBoard extends Vue {
}
