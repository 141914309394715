import { default as DrawStrategy } from './DrawCanvasStrategy';
import Layer from '../../../domain/layer/Layer';
import Canvas from '../../../domain/canvas/Canvas';
import Palette from '../../../domain/palette/Palette';

/**
 * 再描画処理
 */
export default class RedrawCanvasStrategy extends DrawStrategy {
    constructor(canvas: Canvas, private layer: Layer, private palette: Palette) {
        super(canvas);
    }

    public draw() {
        this.canvas.draw(this.layer.toByteArray(this.palette));
    }
}
