import DrawStrategy from './DrawLayerStrategy';
import Layer from '../../../domain/layer/Layer';
import CircleStrokedStrategy from './DrawLayerCircleStrokedStrategy';
import BucketStrategy from './DrawLayerBucketStrategy';
import { SpecialColor } from '@/logics/domain/palette/Palette';

/**
 * 楕円（塗りつぶし）描画処理
 */
export default class DrawLayerCircleStrategy extends DrawStrategy {
    private origin!: Layer; // 描画開始初期レイヤー状態
    private startX!: number;
    private startY!: number;

    public constructor(layer: Layer, colorIndex: number) {
        super(layer, colorIndex);
    }

    public drawStart(x: number, y: number) {
        this.origin = this.layer.clone();
        this.startX = x;
        this.startY = y;
    }

    public drawMiddle(x: number, y: number) {
        this.layer.overwriteFrom(this.origin);
        const left = Math.min(x, this.startX);
        const right = Math.max(x, this.startX);
        const top = Math.min(y, this.startY);
        const bottom = Math.max(y, this.startY);
        const cx = Math.floor((left + right) / 2);
        const cy = Math.floor((top + bottom) / 2);
        if (cx <= left || cx >= right
            || cy <= top || cy >= bottom) {
            return;
        }
        const drawLayer = new Layer(this.layer.width, this.layer.height);
        const circleStrokedStrategy = new CircleStrokedStrategy(drawLayer, SpecialColor.TemporaryColor.index);
        circleStrokedStrategy.drawStart(this.startX, this.startY);
        circleStrokedStrategy.drawMiddle(x, y);
        const bucketStrategy = new BucketStrategy(drawLayer, SpecialColor.TemporaryColor.index);
        bucketStrategy.drawStart(cx, cy);
        bucketStrategy.drawEnd(cx, cy);
        this.layer.pileUp(drawLayer);
        this.layer.replaceColor(SpecialColor.TemporaryColor.index, this.colorIndex);
    }

    public drawEnd(x: number, y: number) {
        // 不要
    }
}
