











import { Vue, Component, Watch } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';

@Component({
    components: {
        CircleIconButton,
    },
})
export default class ToggleSyncButton extends Vue {
    private src: string = require('@/assets/editor/sync.svg');

    @Watch('$store.state.editor.syncRefresh')
    private backColor() {
        if (this.$store.state.editor.syncRefresh) {
            return '#b9ffc8';
        } else {
            return '#e0e0e0';
        }
    }

    private onclick(e: Event) {
        this.$store.commit('editor/changeSyncRefresh', !this.$store.state.editor.syncRefresh);
    }
}
