export default class GlobalMessage {
    public readonly title: string;
    public readonly message: string;
    public readonly okCallback?: () => void;
    public readonly okOnly: boolean;
    public constructor(args: {
        title: string,
        message: string,
        okCallback?: () => void,
        okOnly?: boolean,
    }) {
        this.title = args.title;
        this.message = args.message;
        this.okCallback = args.okCallback;
        this.okOnly = !!args.okOnly;
    }
}
