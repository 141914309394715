import axios, { AxiosError } from 'axios';
import Auth from '@/logics/service/Auth';
import store from '@/store';
import GlobalError from '@/logics/domain/error/GlobalError';
import i18n from '@/i18n';

const BASE_URL: string = (process.env.VUE_APP_API_BASE_URL as string).replace(/\/+$/, '');

const errorCallbacks: { [key: number]: () => void } = {
    401: async () => {
        await Auth.logout();
        location.reload();
    },
    403: async () => {
        await Auth.logout();
        location.reload();
    },
    503: async () => {
        location.reload();
    },
};

export default class Api<T> {

    public constructor(private apiPath: string) {
    }

    public async get(query?: { id: string }) {
        try {
            const url = query
                ? `${this.getApiUrl()}/${query.id}`
                : this.getApiUrl();
            return (await axios.get<T>(url, {
                headers: await this.getCommonHeader(),
            })).data;
        } catch (e) {
            this.throwError(e);
            throw e;
        }
    }

    public async post<U>(data?: T) {
        try {
            return (await axios.post<U>(this.getApiUrl(), data, {
                headers: await this.getCommonHeader(),
            })).data;
        } catch (e) {
            this.throwError(e);
            throw e;
        }
    }

    public async put<U>(data?: T) {
        try {
            return (await axios.put<U>(this.getApiUrl(), data, {
                headers: await this.getCommonHeader(),
            })).data;
        } catch (e) {
            this.throwError(e);
            throw e;
        }
    }

    public async delete<U>(query?: { id: string }) {
        try {
            const url = query
                ? `${this.getApiUrl()}/${query.id}`
                : this.getApiUrl();
            return (await axios.delete<U>(url, {
                headers: await this.getCommonHeader(),
            })).data;
        } catch (e) {
            this.throwError(e);
            throw e;
        }
    }

    private getApiUrl() {
        return `${BASE_URL}/${this.apiPath}`;
    }

    private async getCommonHeader() {
        const token = await Auth.getToken();
        return { Authorization: `Bearer ${token}` };
    }

    private throwError(e: AxiosError) {
        const code = e.response ? e.response.status : 0;
        store.dispatch('throwError', new GlobalError(
            this.getErrorMessage(code),
            errorCallbacks[code],
        ));
    }

    private getErrorMessage(status: number) {
        const messageKey = `errors.api.${status}`;
        const message = i18n.t(messageKey);
        if (message !== messageKey) { // キーと同じ場合は該当メッセージなし
            return message.toString();
        } else {
            return i18n.t(`errors.api.other`).toString();
        }
    }
}
