















import { Vue, Component, Prop } from 'vue-property-decorator';
import { KeyCodes } from '@/logics/constants';

@Component
export default class InputSearch extends Vue {
    @Prop({ default: 300 }) private width!: number;
    @Prop({ default: false }) private allowEmpty!: boolean;
    private icon: string = require('@/assets/form/search.svg');
    private style = {
        width: `${this.width}px`,
    };
    private value = '';

    private clickIcon() {
        this.search();
    }

    private keydown(e: KeyboardEvent) {
        if (e.keyCode === KeyCodes.Enter) {
            this.search();
        }
    }

    private search() {
        if (!this.allowEmpty && !this.value) {
            return;
        }
        this.$emit('search', this.value);
    }
}
