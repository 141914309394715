import DrawStrategy from './DrawLayerStrategy';
import Layer from '../../../domain/layer/Layer';

/**
 * 楕円描画処理
 */
export default class DrawLayerCircleStrokedStrategy extends DrawStrategy {
    private origin!: Layer; // 描画開始初期レイヤー状態
    private startX!: number;
    private startY!: number;

    public constructor(layer: Layer, colorIndex: number) {
        super(layer, colorIndex);
    }

    public drawStart(x: number, y: number) {
        this.origin = this.layer.clone();
        this.startX = x;
        this.startY = y;
    }

    public drawMiddle(x: number, y: number) {
        this.layer.overwriteFrom(this.origin);
        const right = Math.max(this.startX, x);
        const left = Math.min(this.startX, x);
        const bottom = Math.max(this.startY, y);
        const top = Math.min(this.startY, y);
        const rx = Math.floor((right - left) / 2);
        const ry = Math.floor((bottom - top) / 2);
        this.drawEllipse(left + rx, top + ry, rx, ry);
    }

    public drawEnd(x: number, y: number) {
        // 不要
    }

    /**
     * 楕円を描画する
     * @param xc 円の中心X座標
     * @param yc 円の中心Y座標
     * @param rx 円のX方向半径
     * @param ry 円のY方向半径
     */
    private drawEllipse(xc: number, yc: number, rx: number, ry: number) {
        if (rx > ry) {
            let x = rx;
            let r = rx;
            let y = 0;
            while (x >= y) {
                const x1 = Math.floor(x * ry / rx);
                const y1 = Math.floor(y * ry / rx);
                this.layer.set(xc + x, yc + y1, this.colorIndex);
                this.layer.set(xc + x, yc - y1, this.colorIndex);
                this.layer.set(xc - x, yc + y1, this.colorIndex);
                this.layer.set(xc - x, yc - y1, this.colorIndex);
                this.layer.set(xc + y, yc + x1, this.colorIndex);
                this.layer.set(xc + y, yc - x1, this.colorIndex);
                this.layer.set(xc - y, yc + x1, this.colorIndex);
                this.layer.set(xc - y, yc - x1, this.colorIndex);
                r -= (y * 2) + 1;
                y++;
                if (r <= 0) {
                    --x;
                    r += x * 2;
                }
            }
        } else {
            let x = ry;
            let r = ry;
            let y = 0;
            while (x >= y) {
                const x1 = Math.floor(x * rx / ry);
                const y1 = Math.floor(y * rx / ry);
                this.layer.set(xc + x1, yc + y, this.colorIndex);
                this.layer.set(xc + x1, yc - y, this.colorIndex);
                this.layer.set(xc - x1, yc + y, this.colorIndex);
                this.layer.set(xc - x1, yc - y, this.colorIndex);
                this.layer.set(xc + y1, yc + x, this.colorIndex);
                this.layer.set(xc + y1, yc - x, this.colorIndex);
                this.layer.set(xc - y1, yc + x, this.colorIndex);
                this.layer.set(xc - y1, yc - x, this.colorIndex);
                r -= (y * 2) + 1;
                y++;
                if (r <= 0) {
                    --x;
                    r += x * 2;
                }
            }
        }
    }
}

