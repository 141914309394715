import Vue from 'vue';
import VueRouter from 'vue-router';
import MyPageView from '@/views/MyPageView.vue';
import EditorView from '@/views/EditorView.vue';
import LoginView from '@/views/LoginView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import WithdrawCompleteView from '@/views/WithdrawCompleteView.vue';
import Auth from '@/logics/service/Auth';
import Store from '@/store';
import AccountRepository from '@/logics/repository/api/AccountApiRepository';
import QueryProcessor from '@/router/QueryProcessor';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/',
        name: 'mypage',
        component: MyPageView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/editor',
        name: 'editor',
        component: EditorView,
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/withdraw-complete',
        name: 'withdraw-complete',
        component: WithdrawCompleteView,
    },
    {
        path: '/*',
        name: 'notfound',
        component: NotFoundView,
        meta: {
            requiresAuth: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// 認証チェック
router.beforeEach(async (to, from, next) => {
    const user = await Auth.getUser();
    if (to.matched.some((record) => record.meta.requiresAuth)
        && !user) {
        Store.commit('hasHeader', false);
        next('/login');
    } else if (to.name === 'login' && user) {
        Store.commit('hasHeader', true);
        next('/');
    } else {
        Store.commit('hasHeader', to.matched.some((record) => record.meta.requiresAuth));
        if (user) {
            try {
                Store.dispatch('startLoading');
                Store.commit('account/update', await AccountRepository.get());
            } catch {
                Store.dispatch('stopLoading');
            }
        }
        for (const key in to.query) {
            if (key) {
                // パラメータによる割り込み処理
                const processor = QueryProcessor.getProcessor(key);
                if (processor) {
                    processor();
                    router.replace({ query: {} });
                }
            }
        }
        next();
    }
});

export default router;
