



































import { Vue, Component, Prop } from 'vue-property-decorator';
import Auth from '@/logics/service/Auth';
import GlobalError from '@/logics/domain/error/GlobalError';
import ValidatorBuilder from '@/logics/util/ValidatorBuilder';

@Component
export default class ResetPasswordForm extends Vue {
    private succeedSendEmail = false;
    private emailField: string = '';

    private get emailRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required',
                { label: this.$t('form.resetPasswordForm.labels.mailAddress') }))
            .emailFormat(this.$t('errors.validation.malformed',
                { label: this.$t('form.resetPasswordForm.labels.mailAddress') }))
            .build();
    }

    private cancel() {
        this.$emit('cancel');
    }

    private async submit() {
        const form = this.$refs.resetPasswordForm as any;
        if (!form.validate()) {
            return;
        }
        try {
            this.$store.dispatch('startLoading');
            await Auth.sendResetPasswordEmail(this.emailField);
            this.succeedSendEmail = true;
        } catch {
            this.$store.dispatch('throwError',
                new GlobalError(this.$t('errors.exception.auth.resetPasswordError').toString()));
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }
}
