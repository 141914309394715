import { render, staticRenderFns } from "./PaletteBoard.vue?vue&type=template&id=728a570d&scoped=true&"
import script from "./PaletteBoard.vue?vue&type=script&lang=ts&"
export * from "./PaletteBoard.vue?vue&type=script&lang=ts&"
import style0 from "./PaletteBoard.vue?vue&type=style&index=0&id=728a570d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "728a570d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VSpacer,VToolbar,VToolbarTitle,VTooltip})
