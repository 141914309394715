



















import { Vue, Component } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import AccountInformation from '@/components/organisms/account/AccountInformation.vue';
import GlobalMouseEventHandler from '@/logics/util/handler/GlobalMouseEventHandler';

@Component({
    components: {
        CircleIconButton,
        AccountInformation,
    },
})
export default class AccountButton extends Vue {
    private accountIcon = require('@/assets/account/profile.svg');
    private showMenu = false;

    private mounted() {
        // コンテキスト外クリック時の制御
        // （単純にフラグで非表示にするとダイアログ毎消えるのでダイアログ表示中は消さないようにする）
        const el = this.$refs.accountButton as Element;
        GlobalMouseEventHandler.addObserver(el, (e) => {
            setTimeout(() => { // オーバーレイが出るまで少し時間差があるので待つ
                const target = e.target as Element;
                const overlayEl = document.querySelector('.v-overlay--active') as Element;
                if (!el.contains(target) && !overlayEl) {
                    this.showMenu = false;
                }
            }, 200);
        });
    }

    private close() {
        // account-informationから呼び出すダイアログがv-menuのデフォルト挙動で閉じるのを防ぐため
        // 明示的に閉じるボタンが押下された時に閉じるようにする
        this.showMenu = false;
    }
}
