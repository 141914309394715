var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"setting-tag-form",attrs:{"light":"","min-width":"600","height":"500"}},[_c('v-card-title',{staticClass:"white--text blue-grey lighten-3"},[_vm._v(_vm._s(_vm.$t('form.settingTagForm.title')))]),_c('div',{staticClass:"setting-tag-form-contents"},[_c('div',{staticClass:"left-content"},[_c('v-list',_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,on:{"click":_vm.changeSelectItem}},[_c('v-list-item-title',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name))]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('circle-icon-button',{attrs:{"src":_vm.srcRight,"width":24,"height":24},on:{"click":function($event){$event.stopPropagation();return _vm.takeOffTag(i)}}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('form.settingTagForm.tooltip.takeOff')))])])],1)],1)}),1)],1),_c('div',{staticClass:"right-content"},[_c('div',{staticClass:"master-tag-list"},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectIndex),callback:function ($$v) {_vm.selectIndex=$$v},expression:"selectIndex"}},_vm._l((_vm.masterItems),function(item,i){return _c('v-list-item',{key:i,on:{"click":_vm.changeSelectItem}},[_c('v-list-item-title',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name))]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('circle-icon-button',{attrs:{"src":_vm.srcLeft,"width":24,"height":24},on:{"click":function($event){$event.stopPropagation();return _vm.takeOnTag(i)}}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('form.settingTagForm.tooltip.takeOn')))])]),(!_vm.forSelect)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('circle-icon-button',{attrs:{"src":_vm.srcRemove,"width":24,"height":24},on:{"click":function($event){$event.stopPropagation();return _vm.removeTag(i)}}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('form.settingTagForm.tooltip.remove')))])]):_vm._e()],1)],1)}),1)],1)],1),(!_vm.forSelect)?_c('div',{staticClass:"new-tag-input"},[_c('v-form',{ref:"tagForm",on:{"submit":function($event){$event.preventDefault();return _vm.editTag($event)}}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('form.settingTagForm.labels.name'),"type":"string","rules":_vm.tagRules,"autocomplete":"off"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('form.settingTagForm.labels.name'))+" ")]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"color":"success","x-large":""},on:{"click":_vm.editTag}},[_vm._v(_vm._s(_vm.editTagLabel))])]},proxy:true}],null,false,4207927559),model:{value:(_vm.tagField),callback:function ($$v) {_vm.tagField=$$v},expression:"tagField"}})],1)],1):_vm._e()])]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"large":"","width":"150"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("dialog.button.cancel")))]),_c('v-btn',{attrs:{"color":"success","large":"","width":"150"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.forSelect ? _vm.$t("dialog.button.ok") : _vm.$t("dialog.button.edit")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }