import { default as Pixel, PIXEL_UNIT } from '@/logics/domain/canvas/Pixel';
import Palette from '@/logics/domain/palette/Palette';

/**
 * レイヤーモデル
 */
export default class Layer {
    private table: number[][];
    private bytes: Uint8ClampedArray;

    public get width() {
        return this.table[0].length;
    }

    public get height() {
        return this.table.length;
    }

    public constructor(width: number, height: number)
    public constructor(json: any)
    public constructor(...args: any[]) {
        if (args.length >= 2) {
            const width = args[0];
            const height = args[1];
            this.table = [];
            for (let y = 0; y < height; y++) {
                this.table[y] = [];
                for (let x = 0; x < width; x++) {
                    this.table[y][x] = 0;
                }
            }
        } else {
            this.table = args[0].table;
        }
        this.bytes = new Uint8ClampedArray(this.width * this.height * PIXEL_UNIT);
    }

    public getTable() {
        return this.table;
    }

    public set(x: number, y: number, value: number) {
        if (this.isOut(x, y)) {
            return;
        }
        this.table[y][x] = value;
    }

    public get(x: number, y: number): number {
        if (this.isOut(x, y)) {
            return 0;
        }
        return this.table[y][x];
    }

    /**
     * 別レイヤーを透明色を無視して重ねる
     * @param layer 別レイヤー
     */
    public pileUp(layer: Layer) {
        for (let y = 0; y < this.height; y++) {
            for (let x = 0; x < this.width; x++) {
                if (!layer.table[y][x]) { // 透明色は無視
                    continue;
                }
                this.table[y][x] = layer.table[y][x];
            }
        }
    }

    /**
     * 複製の生成
     */
    public clone() {
        const copy = new Layer(this.width, this.height);
        for (let y = 0; y < this.height; y++) {
            for (let x = 0; x < this.width; x++) {
                copy.table[y][x] = this.table[y][x];
            }
        }
        return copy;
    }

    /**
     * 別レイヤーによるレイヤーの上書き
     * @param other 別レイヤー
     */
    public overwriteFrom(other: Layer) {
        for (let y = 0; y < this.height; y++) {
            for (let x = 0; x < this.width; x++) {
                this.table[y][x] = other.table[y][x];
            }
        }
    }

    /**
     * 指定座標がサイズ内か否か（trueの場合は範囲外）
     */
    public isOut(x: number, y: number) {
        return x < 0 || x >= this.width || y < 0 || y >= this.height;
    }

    /**
     * レイヤー内容を指定値分平行移動させる
     * @param shiftX ずらすX軸量
     * @param shiftY ずらすY軸量
     */
    public shift(shiftX: number, shiftY: number) {
        const newLayer = new Layer(this.width, this.height);
        for (let y = 0; y < this.height; y++) {
            for (let x = 0; x < this.width; x++) {
                newLayer.set(shiftX + x, shiftY + y, this.get(x, y));
            }
        }
        this.table = newLayer.table;
    }

    /**
     * 透明色で塗りつぶす
     */
    public clear() {
        for (let y = 0; y < this.height; y++) {
            for (let x = 0; x < this.width; x++) {
                this.table[y][x] = 0;
            }
        }
    }

    // 左右反転
    // left: 反転開始X座標
    // top: 反転開始Y座標
    // right: 反転終点X座標
    // bottom: 反転終点Y座標
    public flipHorizontal(left: number, top: number, right: number, bottom: number) {
        const halfWidth = Math.floor((left + right) / 2);
        for (let y = top; y <= bottom; y++) {
            for (let x = left; x <= halfWidth; x++) {
                const temp = this.table[y][x];
                this.table[y][x] = this.table[y][left + right - x];
                this.table[y][left + right - x] = temp;
            }
        }
    }

    // 上下反転
    // left: 反転開始X座標
    // top: 反転開始Y座標
    // right: 反転終点X座標
    // bottom: 反転終点Y座標
    public flipVertical(left: number, top: number, right: number, bottom: number) {
        const halfHeight = Math.floor((top + bottom) / 2);
        for (let y = top; y <= halfHeight; y++) {
            for (let x = left; x <= right; x++) {
                const temp = this.table[y][x];
                this.table[y][x] = this.table[top + bottom - y][x];
                this.table[top + bottom - y][x] = temp;
            }
        }
    }

    public equals(other: object): boolean {
        if (!(other instanceof Layer) || this.width !== other.width || this.height !== other.height) {
            return false;
        }
        for (let y = 0; y < this.height; y++) {
            for (let x = 0; x < this.width; x++) {
                if (this.table[y][x] !== other.table[y][x]) {
                    return false;
                }
            }
        }
        return true;
    }

    public resize(newWidth: number, newHeight: number) {
        const newLayer = new Layer(newWidth, newHeight);
        const loopWidth = Math.min(newWidth, this.width);
        const loopHeight = Math.min(newHeight, this.height);
        for (let y = 0; y < loopHeight; y++) {
            for (let x = 0; x < loopWidth; x++) {
                newLayer.table[y][x] = this.table[y][x];
            }
        }
        this.table = newLayer.table;
        this.bytes = new Uint8ClampedArray(this.width * this.height * PIXEL_UNIT);
    }

    public toByteArray(palette: Palette): Uint8ClampedArray {
        let i = 0;
        for (let y = 0; y < this.height; y++) {
            for (let x = 0; x < this.width; x++) {
                const color = palette.getColor(this.table[y][x]);
                Pixel.set(this.bytes, i,
                    color.R,
                    color.G,
                    color.B,
                    color.A,
                );
                i += PIXEL_UNIT;
            }
        }
        return this.bytes;
    }

    public replaceColor(srcColorIndex: number, replaceColorIndex: number) {
        for (let y = 0; y < this.height; y++) {
            for (let x = 0; x < this.width; x++) {
                if (this.table[y][x] === srcColorIndex) {
                    this.table[y][x] = replaceColorIndex;
                }
            }
        }
    }
}
