

















import { Vue, Component } from 'vue-property-decorator';
import ToolButton from '@/components/organisms/tool-box/ToolButton.vue';
import ToolButtonPen from '@/components/organisms/tool-box/ToolButtonPen.vue';
import ToolButtonLine from '@/components/organisms/tool-box/ToolButtonLine.vue';
import ToolButtonEraser from '@/components/organisms/tool-box/ToolButtonEraser.vue';
import ToolButtonSelect from '@/components/organisms/tool-box/ToolButtonSelect.vue';
import ToolButtonSyringe from '@/components/organisms/tool-box/ToolButtonSyringe.vue';
import ToolButtonBucket from '@/components/organisms/tool-box/ToolButtonBucket.vue';
import ToolButtonCircle from '@/components/organisms/tool-box/ToolButtonCircle.vue';
import ToolButtonCircleStroked from '@/components/organisms/tool-box/ToolButtonCircleStroked.vue';
import ToolButtonSquare from '@/components/organisms/tool-box/ToolButtonSquare.vue';
import ToolButtonSquareStroked from '@/components/organisms/tool-box/ToolButtonSquareStroked.vue';

@Component({
  components: {
    ToolButton,
    ToolButtonPen,
    ToolButtonLine,
    ToolButtonEraser,
    ToolButtonSelect,
    ToolButtonSyringe,
    ToolButtonBucket,
    ToolButtonCircle,
    ToolButtonCircleStroked,
    ToolButtonSquare,
    ToolButtonSquareStroked,
  },
})
export default class ToolBox extends Vue {
}
