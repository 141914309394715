export default class GlobalError {
    public readonly name = 'GlobalError';
    public readonly message: string;
    public readonly stack?: string;
    public readonly closeCallback?: () => void;
    public constructor(message: string, closeCallback?: () => void) {
        this.message = message;
        this.stack = (new Error()).stack;
        this.closeCallback = closeCallback;
    }
}
