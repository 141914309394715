





import { Vue, Component, Prop } from 'vue-property-decorator';
import MouseEventHandler from '@/logics/util/handler/MouseEventHandler';

@Component
export default class SelectRegion extends Vue {
    @Prop({ required: true }) private rect!: { left: number, top: number, width: number, height: number };
    @Prop({ required: true }) private scale!: number;
    private startX!: number;
    private startY!: number;
    private originLeft!: number;
    private originTop!: number;
    private drawRect = {
        left: this.rect.left,
        top: this.rect.top,
        width: this.rect.width,
        height: this.rect.height,
    };
    private mouseEventHandler!: MouseEventHandler;

    private mounted() {
        this.drawRect = {
            left: this.rect.left,
            top: this.rect.top,
            width: this.rect.width,
            height: this.rect.height,
        };
        this.originLeft = this.rect.left;
        this.originTop = this.rect.top;

        const element = this.$refs.selectedBorder as Element;
        this.mouseEventHandler = new MouseEventHandler({
            // el: this.$refs.selectedBorder as Element,
            el: document.body as Element,
            onLeftButtonDown: (e) => {
                this.startX = e.clientX;
                this.startY = e.clientY;
            },
            onLeftButtonMove: (e) => {
                const distanceX = Math.floor((e.clientX - this.startX) / this.scale);
                const distanceY = Math.floor((e.clientY - this.startY) / this.scale);
                this.drawRect.left = this.rect.left + distanceX;
                this.drawRect.top = this.rect.top + distanceY;
                this.$emit('drag', {
                    x: (this.rect.left - this.originLeft) + distanceX,
                    y: (this.rect.top - this.originTop) + distanceY,
                });
            },
            onLeftButtonUp: (e) => {
                this.rect.left = this.drawRect.left;
                this.rect.top = this.drawRect.top;
            },
            onOther: (e) => {
                const rect = element.getBoundingClientRect();
                if (e.clientX < rect.left || e.clientX > rect.right
                    || e.clientY < rect.top || e.clientY > rect.bottom) {
                    this.$emit('commit');
                }
            },
        });
    }

    private beforeDestroy() {
        this.mouseEventHandler.releaseEvents();
    }

    private get style() {
        return {
            left: `${this.drawRect.left * this.scale}px`,
            top: `${this.drawRect.top * this.scale}px`,
            width: `${this.drawRect.width * this.scale}px`,
            height: `${this.drawRect.height * this.scale}px`,
        };
    }
}
