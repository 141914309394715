import Layer from '../../../domain/layer/Layer';

/**
 * 描画処理インターフェース
 */
export default abstract class DrawCanvasStrategy {

    constructor(protected layer: Layer, protected colorIndex: number) {
    }

    public changeLayer(layer: Layer) {
        this.layer = layer;
    }

    public changeColorIndex(index: number) {
        this.colorIndex = index;
    }

    /**
     * 描画開始処理
     * @param 描画引数
     */
    public abstract drawStart(x: number, y: number): void;

    /**
     * 中間描画処理
     * @param 描画引数
     */
    public abstract drawMiddle(x: number, y: number): void;

    /**
     * 描画終了
     * @param 描画引数
     */
    public abstract drawEnd(x: number, y: number): void;

    /**
     * アンドゥを積む必要があるか
     */
    public necessaryUndo() {
        return true;
    }
}
