

















import { Vue, Component } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import FrameAnimationPreview from '@/components/organisms/frame/FrameAnimationPreview.vue';

@Component({
    components: {
        CircleIconButton,
        FrameAnimationPreview,
    },
})
export default class FrameAnimationButton extends Vue {
    private icon = require('@/assets/frame/video-camera.svg');
    private showPreviewDialog = false;
}
