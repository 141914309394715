













































import { Vue, Component } from 'vue-property-decorator';
import { FrameInfo } from '@/store/editor';
import LayerDrawer from '@/components/molecules/LayerDrawer.vue';
import Gif from '@/logics/util/Gif';

@Component({
    components: {
        LayerDrawer,
    },
})
export default class FrameAnimationPreview extends Vue {
    private currentIndex = 0;
    private scale = 1;
    private interval = 500;

    private get drawers() {
        return this.$refs.drawers as LayerDrawer[];
    }

    private get frameInfos(): FrameInfo[] {
        return this.$store.state.editor.frameInfos;
    }

    private get palette() {
        return this.$store.state.editor.colorPalette;
    }

    private get size() {
        return this.scale * Math.max(this.frameInfos[0].layer.width, this.frameInfos[0].layer.height);
    }

    private get delay() {
        return 1000 - this.interval;
    }

    private get drawersStyle() {
        return {
            width: `${this.size}px`,
            height: `${this.size}px`,
            // 単純に中央配置にするとスクロール時に上部が途切れるので調整
            margin: this.size > 300 ? `10px auto` : undefined,
        };
    }

    private mounted() {
        this.refresh();
        this.startAnimation();
    }

    private refresh() {
        this.drawers.forEach((d) => d.refresh());
    }

    private async downloadGif() {
        try {
            this.$store.dispatch('showProgress', 0);
            const gif = new Gif(
                this.drawers[0].getLayerCanvas().width,
                this.drawers[0].getLayerCanvas().height,
                `${this.$store.state.editor.name}.gif`);
            for (let i = 0; i < this.drawers.length; i++) {
                await new Promise(async (resolve) => {
                    setTimeout(() => {
                        gif.addFrame(this.drawers[i].getLayerCanvas().context, this.delay);
                        resolve(null);
                    }, 600);
                });
                this.$store.dispatch('showProgress', Math.floor((i + 1) / this.drawers.length * 100));
            }
            gif.download();
        } finally {
            this.$store.dispatch('showProgress', null);
        }
    }

    private startAnimation() {
        window.setTimeout(() => {
            this.currentIndex++;
            if (this.currentIndex === this.frameInfos.length) {
                this.currentIndex = 0;
            }
            this.startAnimation();
        }, this.delay);
    }

    private close() {
        this.$emit('close');
    }
}
