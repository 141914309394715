export const PIXEL_UNIT = 4;

export default class Pixel {

    public static set(data: Uint8ClampedArray, index: number, r: number, g: number, b: number, a: number) {
        this.setR(data, index, r);
        this.setG(data, index, g);
        this.setB(data, index, b);
        this.setA(data, index, a);
    }
    public static getR(data: Uint8ClampedArray, index: number) {
        return data[index + 0];
    }
    public static getG(data: Uint8ClampedArray, index: number) {
        return data[index + 1];
    }
    public static getB(data: Uint8ClampedArray, index: number) {
        return data[index + 2];
    }
    public static getA(data: Uint8ClampedArray, index: number) {
        return data[index + 3];
    }
    public static setR(data: Uint8ClampedArray, index: number, r: number) {
        data[index + 0] = r;
    }
    public static setG(data: Uint8ClampedArray, index: number, g: number) {
        data[index + 1] = g;
    }
    public static setB(data: Uint8ClampedArray, index: number, b: number) {
        data[index + 2] = b;
    }
    public static setA(data: Uint8ClampedArray, index: number, a: number) {
        data[index + 3] = a;
    }
}
