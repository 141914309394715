import Api from './Api';

class SettlementApiRepository {
    private createSubscriptionCheckoutApi = new Api('settlement/create-subscription-checkout-session');
    private createSetupCheckoutApi = new Api('settlement/create-setup-checkout-session');
    private deleteSubscriptionApi = new Api('settlement/delete-subscription');
    private changeCustomerEmailApi = new Api('settlement/change-customer-email');
    private deleteCustomerApi = new Api('settlement/delete-customer');

    /**
     * 決済作成用セッション生成
     */
    public async createSubscriptionCheckoutSession(settlementCustomerId?: string, settlementCustomerEmail?: string) {
        const sessionId = await this.createSubscriptionCheckoutApi.post({
            customerId: settlementCustomerId,
            customerEmail: settlementCustomerEmail,
        });
        return sessionId as string;
    }

    /**
     * 決済情報更新用セッション生成
     */
    public async createSetupCheckoutSession(settlementCustomerId: string, settlementSubscriptionId: string) {
        const sessionId = await this.createSetupCheckoutApi.post({
            customerId: settlementCustomerId,
            subscriptionId: settlementSubscriptionId,
        });
        return sessionId as string;
    }

    /**
     * サブスクリプションの削除（終了）
     */
    public async deleteSubscription(settlementCustomerId: string, settlementSubscriptionId: string) {
        return await this.deleteSubscriptionApi.post({
            customerId: settlementCustomerId,
            subscriptionId: settlementSubscriptionId,
        });
    }

    /**
     * 顧客のメールアドレス変更
     */
    public async changeCustomerEmail(settlementCustomerId: string, newEmail: string) {
        return await this.changeCustomerEmailApi.post({
            customerId: settlementCustomerId,
            customerEmail: newEmail,
        });
    }

    /**
     * 顧客の削除（サブスクリプションも消える）
     */
    public async deleteCustomer(settlementCustomerId: string) {
        return await this.deleteCustomerApi.post({
            customerId: settlementCustomerId,
        });
    }
}
export default new SettlementApiRepository();
