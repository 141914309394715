import store from '@/store';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';
import { AccountType } from '@/store/account';
import { StoreData } from '@/store/editor';

interface PrivilegeSetting {
    imageFrameLimit: number; // 1画像追加可能フレーム数制限
    imageLayerLimit: number; // 1画像追加可能レイヤー数制限
    imageDotsLimit: number; // 1画像保存可能ドット総数制限
    imageSizeLimit: {
        width: number;
        height: number;
    };
}

const PrivilegeSettings: { [key: string]: PrivilegeSetting } = {
    [AccountType.Free]: {
        imageFrameLimit: 4,
        imageLayerLimit: 4,
        imageDotsLimit: 64 * 64 * 16,
        imageSizeLimit: {
            width: 64,
            height: 64,
        },
    },
    [AccountType.Premium]: {
        imageFrameLimit: Number.MAX_SAFE_INTEGER,
        imageLayerLimit: Number.MAX_SAFE_INTEGER,
        imageDotsLimit: 128 * 128 * 96,
        imageSizeLimit: {
            width: 128,
            height: 128,
        },
    },
};

class Privilege {

    public isPremium(): boolean {
        return store.getters['account/isPremium'];
    }

    public isFree(): boolean {
        return store.getters['account/isFree'];
    }

    public getPrivilegeSetting(): PrivilegeSetting {
        const type: AccountType = store.getters['account/getType'];
        return PrivilegeSettings[type];
    }

    public runWhenWithinLimit(
        callback: () => void,
        deniedMessage?: GlobalMessage) {
        const privilegeSetting = this.getPrivilegeSetting();
        const size = store.getters['editor/size'];
        const frameCount = store.getters['editor/frameCount']();
        const layerDotsCount = store.getters['editor/totalLayerDotsCount']();
        const totalLayerCount = store.getters['editor/totalLayerCount']();
        const totalDotsCount = layerDotsCount * totalLayerCount;
        if (
            size.width <= privilegeSetting.imageSizeLimit.width
            && size.height <= privilegeSetting.imageSizeLimit.height
            && frameCount <= privilegeSetting.imageFrameLimit
            && totalDotsCount <= privilegeSetting.imageDotsLimit
        ) {
            callback();
        } else if (deniedMessage) {
            store.dispatch('showMessage', deniedMessage);
        }
    }

    public runWhenAllowAddLayer(
        callback: () => void,
        currentLayerCount: number,
        addLayerCount: number,
        deniedMessage?: GlobalMessage) {
        const privilegeSetting = this.getPrivilegeSetting();
        const layerDotsCount = store.getters['editor/totalLayerDotsCount']();
        const totalLayerCount = store.getters['editor/totalLayerCount']();
        const totalDotsCount = layerDotsCount * (totalLayerCount + addLayerCount);
        if (
            currentLayerCount < privilegeSetting.imageLayerLimit
             && totalDotsCount <= privilegeSetting.imageDotsLimit
        ) {
            callback();
        } else if (deniedMessage) {
            store.dispatch('showMessage', deniedMessage);
        }
    }

    public runWhenAllowAddFrame(
        callback: () => void,
        currentFrameCount: number,
        addLayerCount: number,
        deniedMessage?: GlobalMessage) {
        const privilegeSetting = this.getPrivilegeSetting();
        const layerDotsCount = store.getters['editor/totalLayerDotsCount']();
        const totalLayerCount = store.getters['editor/totalLayerCount']();
        const totalDotsCount = layerDotsCount * (totalLayerCount + addLayerCount);
        if (
            currentFrameCount < privilegeSetting.imageLayerLimit
            && totalDotsCount <= privilegeSetting.imageDotsLimit
        ) {
            callback();
        } else if (deniedMessage) {
            store.dispatch('showMessage', deniedMessage);
        }
    }
}
export default new Privilege();
