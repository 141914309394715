











import { Vue, Component, Watch } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import GlobalKeyEventHandler from '@/logics/util/handler/GlobalKeyEventHandler';
import { KeyCodes } from '@/logics/constants';

@Component({
    components: {
        CircleIconButton,
    },
})
export default class UndoButton extends Vue {
    private src: string = require('@/assets/editor/undo.svg');

    private mounted() {
        GlobalKeyEventHandler.addObserver(this.$refs.undoButton as Element, [KeyCodes.Ctrl, KeyCodes.Z], () => {
            this.onclick();
        });
    }

    @Watch('$store.state.editor.unredo')
    private backColor() {
        if (this.$store.getters['editor/canUndo']) {
            return '#b9ffc8';
        } else {
            return '#e0e0e0';
        }
    }

    private onclick() {
        this.$store.dispatch('editor/undo');
    }
}
