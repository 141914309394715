import Store from '@/store';
import i18n from '@/i18n';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';

type Processor = () => void;

class QueryProcessor {
    private static readonly PROCESSOR_MAP: { [key: string]: Processor } = {
        'success-subscription': () => {
            Store.dispatch('showMessage', new GlobalMessage({
                okOnly: true,
                title: i18n.t('account.plan.successToPremium.title').toString(),
                message: i18n.t('account.plan.successToPremium.message').toString(),
                okCallback: async () => {
                    location.reload();
                },
            }));
        },
        'success-change-credit': () => {
            Store.dispatch('showMessage', new GlobalMessage({
                okOnly: true,
                title: i18n.t('account.plan.successChangeCredit.title').toString(),
                message: i18n.t('account.plan.successChangeCredit.message').toString(),
            }));
        },
    };

    public getProcessor(key: string) {
        return QueryProcessor.PROCESSOR_MAP[key];
    }
}

export default new QueryProcessor();
