










import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FrameList from '@/components/organisms/frame/FrameList.vue';
import FrameAnimationButton from '@/components/organisms/frame/FrameAnimationButton.vue';
import Privilege from '@/logics/service/Privilege';

@Component({
    components: {
        FrameList,
        FrameAnimationButton,
    },
})
export default class FrameBoard extends Vue {

    private get isPremium(): boolean {
        return Privilege.isPremium();
    }
}
