import DrawStrategy from './DrawLayerStrategy';
import PenStrategy from './DrawLayerPenStrategy';
import Layer from '../../../domain/layer/Layer';

/**
 * 矩形（塗りつぶし）描画処理
 */
export default class DrawLayerSquareStrategy extends DrawStrategy {
    private origin!: Layer; // 描画開始初期レイヤー状態
    private startX!: number;
    private startY!: number;

    public constructor(layer: Layer, colorIndex: number) {
        super(layer, colorIndex);
    }

    public drawStart(x: number, y: number) {
        this.origin = this.layer.clone();
        this.startX = x;
        this.startY = y;
    }

    public drawMiddle(x: number, y: number) {
        this.layer.overwriteFrom(this.origin);
        const left = Math.min(x, this.startX);
        const right = Math.max(x, this.startX);
        const top = Math.min(y, this.startY);
        const bottom = Math.max(y, this.startY);
        for (let loopY = top; loopY <= bottom; loopY++) {
            for (let loopX = left; loopX <= right; loopX++) {
                this.layer.set(loopX, loopY, this.colorIndex);
            }
        }
    }

    public drawEnd(x: number, y: number) {
        // 不要
    }
}
