var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-item-hover-menu",on:{"click":_vm.onclickBody}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"copy"},'span',attrs,false),on),[_c('circle-icon-button',{attrs:{"src":_vm.srcCopy,"width":20,"height":20},on:{"click":function($event){$event.stopPropagation();return _vm.onclickCopy($event)}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('mypage.listItemHoverMenus.copyMenu.tooltip')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"remove"},'span',attrs,false),on),[_c('circle-icon-button',{attrs:{"src":_vm.srcRemove,"width":20,"height":20},on:{"click":function($event){$event.stopPropagation();return _vm.onclickRemove($event)}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('mypage.listItemHoverMenus.removeMenu.tooltip')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"tag"},'span',attrs,false),on),[_c('circle-icon-button',{attrs:{"src":_vm.srcTag,"width":20,"height":20},on:{"click":function($event){$event.stopPropagation();return _vm.onclickTag($event)}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('mypage.listItemHoverMenus.tagMenu.tooltip')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }