import { render, staticRenderFns } from "./SettingAuxiliaryGridButton.vue?vue&type=template&id=6f0acf3a&scoped=true&"
import script from "./SettingAuxiliaryGridButton.vue?vue&type=script&lang=ts&"
export * from "./SettingAuxiliaryGridButton.vue?vue&type=script&lang=ts&"
import style0 from "./SettingAuxiliaryGridButton.vue?vue&type=style&index=0&id=6f0acf3a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f0acf3a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VList,VListItem,VListItemTitle,VMenu,VTooltip})
