export const KeyCodes = {
    A: 65,
    C: 67,
    S: 83,
    V: 86,
    X: 88,
    Y: 89,
    Z: 90,
    Enter: 13,
    Ctrl: 17,
    Space: 32,
    Delete: 46,
    Left: 37,
    Up: 38,
    Right: 39,
    Down: 40,
};
