

























































import { Vue, Component, Watch } from 'vue-property-decorator';
import GlobalError from '@/logics/domain/error/GlobalError';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';
import GlobalAlert from '@/logics/domain/common/GlobalAlert';
import HeaderBarView from '@/views/HeaderBarView.vue';
import EditorView from '@/views/EditorView.vue';
import MyPageView from '@/views/MyPageView.vue';

@Component({
    components: {
        HeaderBarView,
        MyPageView,
        EditorView,
    },
})
export default class App extends Vue {
    private alertText = '';
    private messageText = '';

    private get error(): GlobalError {
        return this.$store.state.error as GlobalError;
    }

    private get message(): GlobalMessage {
        return this.$store.state.message as GlobalMessage;
    }

    private get alert(): GlobalAlert {
        const alert = this.$store.state.alert as GlobalAlert;
        if (alert && alert.closeTime) {
            // フェードアウトする時にテキストが空になって潰さないために、
            // テキスト自体は別変数で保持する
            this.alertText = alert.message;
            setTimeout(() => {
                this.$store.dispatch('closeAlert');
            }, alert.closeTime);
        }
        return alert;
    }

    private created() {
        document.title = this.$t('app.title').toString();
    }

    private closeAlert() {
        this.$store.dispatch('closeAlert');
    }

    private closeMessage(ok: boolean) {
        if (ok && this.message && this.message.okCallback) {
            this.message.okCallback();
        }
        this.$store.commit('setMessage', null);
    }

    private closeErrorMessage() {
        if (this.error && this.error.closeCallback) {
            this.error.closeCallback();
        }
        this.$store.commit('setError', null);
    }
}
