









import { Vue, Component, Prop } from 'vue-property-decorator';
import { Tag } from '@/logics/repository/api/TagApiRepository';
import IconImage from '@/components/molecules/IconImage.vue';

@Component({
    components: {
        IconImage,
    },
})
export default class TagList extends Vue {
    protected iconSrc: string = require('@/assets/layer/tag.svg');

    @Prop({ default: 3 }) private maxShowCount: number = 3;
    @Prop({ default: () => [] }) private items!: Tag[];

    private get showItems() {
        return this.items.slice(0, this.maxShowCount);
    }

    private get isOverMaxCount() {
        return this.items.length > this.maxShowCount;
    }
}
