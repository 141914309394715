import { render, staticRenderFns } from "./PaletteBoardMenu.vue?vue&type=template&id=4bd71b6a&scoped=true&"
import script from "./PaletteBoardMenu.vue?vue&type=script&lang=ts&"
export * from "./PaletteBoardMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd71b6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VDialog,VList,VListItem,VListItemTitle,VMenu})
