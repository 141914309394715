
import { Component, Prop } from 'vue-property-decorator';
import ToolButton from '@/components/organisms/tool-box/ToolButton.vue';
import { StrategyType } from '@/logics/service/layer/strategy/DrawLayerStrategyFactory';

@Component
export default class ToolButtonBucket extends ToolButton {
    protected type: StrategyType = StrategyType.Bucket;
    protected iconSrc: string = require('@/assets/tool-box/bucket.svg');

    protected mounted() {
        this.tooltip = this.$t('editor.toolButtons.tooltip.bucket').toString();
    }
}
