


import { Vue, Component } from 'vue-property-decorator';

@Component
export default class NotFoundView extends Vue {

    private mounted() {
        // 不正遷移はマイページへ飛ばす
        this.$router.push({ name: 'mypage' });
    }
}
