



















import { Vue, Component, Prop } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import { default as OutputImageForm, FormResult } from '@/components/organisms/form/OutputImageForm.vue';

@Component({
    components: {
        CircleIconButton,
        OutputImageForm,
    },
})
export default class OutputImageButton extends Vue {
    private showOutputDialog = false;
    private src: string = require('@/assets/editor/file-png.svg');
    private backColor = '#e0e0e0';

    private onmouseover() {
        this.backColor = '#b9ffc8';
    }

    private onmouseleave() {
        this.backColor = '#e0e0e0';
    }

    private output(result: FormResult) {
        this.$store.dispatch('editor/requestOutputImage', result);
        this.showOutputDialog = false;
    }
}
