
import { Component, Prop } from 'vue-property-decorator';
import ToolButton from '@/components/organisms/tool-box/ToolButton.vue';
import { StrategyType } from '@/logics/service/layer/strategy/DrawLayerStrategyFactory';

@Component
export default class ToolButtonCircle extends ToolButton {
    protected type: StrategyType = StrategyType.Circle;
    protected iconSrc: string = require('@/assets/tool-box/circle.svg');

    protected mounted() {
        this.tooltip = this.$t('editor.toolButtons.tooltip.circle').toString();
    }
}
