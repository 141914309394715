


















import { Vue, Component, Prop } from 'vue-property-decorator';
import Palette from '@/logics/domain/palette/Palette';

@Component
export default class PaletteBoardPreview extends Vue {
    @Prop() private palette!: Palette;
}
