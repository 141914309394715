










import { Vue, Component, Watch } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';

@Component({
    components: {
        CircleIconButton,
    },
})
export default class ToggleGridButton extends Vue {
    private src: string = require('@/assets/editor/grid.svg');

    @Watch('$store.state.editor.showGrid')
    private backColor() {
        if (this.$store.state.editor.showGrid) {
            return '#b9ffc8';
        } else {
            return '#e0e0e0';
        }
    }

    private onclick(e: Event) {
        this.$store.commit('editor/changeShowGrid', !this.$store.state.editor.showGrid);
    }
}
