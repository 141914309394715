




































import { Vue, Component, Prop } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';

@Component({
    components: {
        CircleIconButton,
    },
})
export default class FrameHoverMenu extends Vue {
    @Prop({ required: true }) private hideclose!: boolean;
    @Prop({ required: true }) private hidefront!: boolean;
    @Prop({ required: true }) private hideback!: boolean;
    private srcFront = require('@/assets/layer/arrow-left.svg');
    private srcBack = require('@/assets/layer/arrow-right.svg');
    private srcCopy = require('@/assets/layer/copy.svg');
    private srcClose = require('@/assets/layer/close.svg');

    private onclickBody() {
        this.$emit('body');
    }

    private onclickFront(e: MouseEvent) {
        this.$emit('front');
    }

    private onclickBack(e: MouseEvent) {
        this.$emit('back');
    }

    private onclickCopy(e: MouseEvent) {
        this.$emit('copy');
    }

    private onclickClose(e: MouseEvent) {
        this.$emit('close');
    }
}
