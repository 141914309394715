
















































import { Vue, Component, Prop } from 'vue-property-decorator';
import Auth from '@/logics/service/Auth';
import ValidatorBuilder from '@/logics/util/ValidatorBuilder';

@Component
export default class LoginForm extends Vue {
    @Prop({ default: false }) private forReauth!: boolean;
    @Prop({ default: false }) private showCancel!: boolean;
    private emailField: string = '';
    private passwordField: string = '';
    private errorMessage: string | null = '';
    private disabledLogin = false;

    private get title() {
        return this.forReauth
            ? this.$t('form.loginForm.reauthTitle').toString()
            : this.$t('form.loginForm.title').toString();
    }

    private get submitLabel() {
        return this.forReauth
            ? this.$t('dialog.button.ok').toString()
            : this.$t('form.loginForm.labels.submit').toString();
    }

    private get emailRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required',
                { label: this.$t('form.loginForm.labels.mailAddress') }))
            .build();
    }

    private get passwordRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required',
                { label: this.$t('form.loginForm.labels.password') }))
            .build();
    }

    private mounted() {
        if (this.forReauth) {
            this.emailField = this.$store.state.account.email;
        }
    }

    private addAccount() {
        this.$emit('addAccount');
    }

    private resetPassword() {
        this.$emit('resetPassword');
    }

    private async submit() {
        this.disabledLogin = true;
        this.errorMessage = null;
        const form = this.$refs.loginForm as any;
        if (!form.validate()) {
            this.disabledLogin = false;
            return;
        }
        try {
            this.$store.dispatch('startLoading');
            const res = await Auth.login(this.emailField, this.passwordField);
            if (!(res.user!.emailVerified)) {
                this.errorMessage = this.$t('errors.exception.auth.loginWithoutConfirmEmail').toString();
            }
        } catch (e) {
            this.errorMessage = this.$t('errors.exception.auth.loginError').toString();
        } finally {
            this.$store.dispatch('stopLoading');
        }
        if (!this.errorMessage) {
            this.$emit('succeedLogin');
        } else {
            this.disabledLogin = false;
        }
    }

    private async cancel() {
        this.$emit('cancel');
    }
}
