

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import IconImage from '@/components/molecules/IconImage.vue';

@Component({
    components: {
        IconImage,
    },
})
export default class PaletteButton extends Vue {
    @Prop({ required: true }) private index!: number;
    @Prop({ required: true }) private color!: string;
    @Prop({ required: false }) private selected!: boolean;
    private paletteColor = this.color;
    private pickerWidth = '250px';
    private showPicker: boolean  = false;
    private selectedIconSrc: string = require('@/assets/palette/done.svg');

    @Watch('paletteColor')
    private colorChanged() {
        this.$emit('change', this.index, this.paletteColor);
    }

    private onmouseup(e: MouseEvent) {
        if (e.button === 2) {
            this.showPicker = true;
        } else {
            this.showPicker = false;
            this.$emit('select', this.index);
        }
    }

    private ondragstart(e: DragEvent) {
        if (!e.dataTransfer) {
            return;
        }
        e.dataTransfer.setData('text/plain', this.color);
    }

    private ondrop(e: DragEvent) {
        if (!e.dataTransfer) {
            return;
        }
        const color = e.dataTransfer.getData('text/plain');
        if (color) {
            this.$emit('change', this.index, color);
            this.paletteColor = color;
        }
    }
}
