import { default as Pixel, PIXEL_UNIT } from './Pixel';
import Color from '../palette/Color';

export type ColorData = [number, number, number, number];
// 引数のcolorDataが現在値、値を更新する場合は戻り値を返す
export type DataCallback = (colorData: ColorData, x: number, y: number) => ColorData | void;

/**
 * HTMLCanvasElementのラッパークラス
 */
export default class Canvas {
    private canvas: HTMLCanvasElement;
    private canvasContext: CanvasRenderingContext2D;

    public constructor(width: number, height: number);
    public constructor(canvas: HTMLCanvasElement);
    public constructor(...args: any[]) {
        if (args[0] && args[1]) {
            const canvasElement = document.createElement('canvas') as HTMLCanvasElement;
            canvasElement.width = +args[0];
            canvasElement.height = +args[1];
            this.canvas = canvasElement;
        } else {
            this.canvas = args[0] as HTMLCanvasElement;
        }
        this.canvasContext = this.canvas.getContext('2d', { alpha: true }) as CanvasRenderingContext2D;
    }

    public get context() {
        return this.canvasContext;
    }

    public get width() {
        return this.canvas.width;
    }

    public get height() {
        return this.canvas.height;
    }

    public resize(width: number, height: number) {
        this.canvas.width = width;
        this.canvas.height = height;
    }

    public draw(array: Uint8ClampedArray) {
        const imageData = this.context.getImageData(0, 0, this.width, this.height);
        imageData.data.set(array);
        this.context.putImageData(imageData, 0, 0);
    }

    /**
     * 別のキャンバスをマッピングする
     * @param other マッピングするキャンバス
     */
    public mappingFrom(other: Canvas): void;
    public mappingFrom(other: Canvas, toX: number, toY: number): void;
    public mappingFrom(...args: any[]) {
        this.context.imageSmoothingEnabled = false;
        if (args.length === 1) {
            // マッピング元をこのキャンバスサイズで描画する
            this.context.drawImage(
                args[0].canvas,
                0,
                0,
                args[0].canvas.width,
                args[0].canvas.height,
                0,
                0,
                this.canvas.width,
                this.canvas.height);
        } else {
            // マッピング元をこのキャンバスの指定座標に元サイズで描画する
            this.context.drawImage(
                args[0].canvas,
                0,
                0,
                args[0].canvas.width,
                args[0].canvas.height,
                args[1] || 0,
                args[2] || 0,
                args[0].width,
                args[0].height);
        }
    }

    /**
     * PngのBase64文字列に変換
     */
    public toPngDataUrl() {
        return this.canvas.toDataURL('image/png');
    }

    public async toBlob(): Promise<Blob> {
        const promise = new Promise<Blob>((resolve) => {
            if ((this.canvas as any).msToBlob) { // for Edge
                resolve((this.canvas as any).msToBlob());
            } else {
                this.canvas.toBlob((blob) => {
                    resolve(blob!);
                }, 'image/png');
            }
        });
        return promise;
    }

    public async toArrayBuffer(): Promise<ArrayBuffer> {
        const blob = await this.toBlob();
        const promise = new Promise<ArrayBuffer>(async (resolve) => {
            const reader = new FileReader();
            reader.addEventListener('loadend', () => {
                resolve(reader.result as ArrayBuffer);
            });
            reader.readAsArrayBuffer(blob!);
        });
        return promise;
    }

    /**
     * キャンバス画像をダウンロードする
     * @param downloadName ダウンロードファイル名
     * @param alpha 透過色（未指定時は透過しない）
     */
    public async download(downloadName: string, alpha?: Color) {
        const downloadCanvas = this.cloneForWork(alpha);
        // ダウンロード
        const anchor = document.createElement('a') as HTMLAnchorElement;
        anchor.href = window.URL.createObjectURL(await downloadCanvas.toBlob());
        anchor.download = downloadName;
        anchor.click();
    }

    /**
     * キャンバス作業用クローンを作成する（DOMツリー上へは追加しない）
     * @param alpha 透過色（未指定時は透過しない）
     */
    public cloneForWork(alpha?: Color): Canvas {
        const canvasElement = document.createElement('canvas') as HTMLCanvasElement;
        canvasElement.width = this.width;
        canvasElement.height = this.height;
        const workCanvas = new Canvas(canvasElement);
        workCanvas.mappingFrom(this);
        // 透過色を透過させる
        if (alpha) {
            const length = this.width * this.height * PIXEL_UNIT;
            const imageData = workCanvas.context.getImageData(0, 0, workCanvas.width, workCanvas.height);
            const data = imageData.data;
            for (let i = 0; i < length; i += PIXEL_UNIT) {
                if (alpha.R === Pixel.getR(data, i)
                    && alpha.G === Pixel.getG(data, i)
                    && alpha.B === Pixel.getB(data, i)) {
                    Pixel.setA(data, i, 0);
                }
            }
            workCanvas.context.putImageData(imageData, 0, 0);
        }
        return workCanvas;
    }
}
