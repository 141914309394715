var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frame-hover-menu",on:{"click":_vm.onclickBody}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"front"},'span',attrs,false),on),[(!_vm.hidefront)?_c('circle-icon-button',{attrs:{"src":_vm.srcFront,"width":20,"height":20},on:{"click":function($event){$event.stopPropagation();return _vm.onclickFront($event)}}}):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('layerPreview.frameHoverMenus.frontMenu.tooltip')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"back"},'span',attrs,false),on),[(!_vm.hideback)?_c('circle-icon-button',{attrs:{"src":_vm.srcBack,"width":20,"height":20},on:{"click":function($event){$event.stopPropagation();return _vm.onclickBack($event)}}}):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('layerPreview.frameHoverMenus.backMenu.tooltip')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"copy"},'span',attrs,false),on),[_c('circle-icon-button',{attrs:{"src":_vm.srcCopy,"width":20,"height":20},on:{"click":function($event){$event.stopPropagation();return _vm.onclickCopy($event)}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('layerPreview.frameHoverMenus.copyMenu.tooltip')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"close"},'span',attrs,false),on),[(!_vm.hideclose)?_c('circle-icon-button',{attrs:{"src":_vm.srcClose,"width":20,"height":20},on:{"click":function($event){$event.stopPropagation();return _vm.onclickClose($event)}}}):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('layerPreview.frameHoverMenus.closeMenu.tooltip')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }