import { StoreData } from '@/store/editor';
import Api from './Api';
import Palette from '@/logics/domain/palette/Palette';
import Layer from '@/logics/domain/layer/Layer';

interface Editor { // サーバー側形式
    id: string;
    accountId: string;
    name: string;
    width: number;
    height: number;
    palette: Array<{ r: number, g: number, b: number, a: number }>;
    frames: Array<{
        id: string;
        layers: Array<{
            id: string;
            table: number[][];
        }>;
    }>;
    registeredAt?: string;
    updatedAt?: string;
    additional: {
        thumbnail?: string;
        tags?: string[];
    };
}

interface UpdateResponse {
    registeredAt: string;
    updatedAt: string;
}

interface SearchObject {
    accountId: string;
    keyword: string;
    tags: string[];
    pageUnit: number;
    page: number;
}

interface UpdateTagObject {
    id: string;
    accountId: string;
    tags: string[];
}

export interface SearchResponse {
    infos: SearchInfo[];
    totalCount: number;
}

export interface SearchInfo {
    id: string;
    name: string;
    tags: string[];
    thumbnail: string;
}

class EditorApiRepository {
    private api = new Api<Editor>('editor');
    private searchApi = new Api<SearchObject>('editor/search');
    private recentApi = new Api<SearchResponse>('editor/recent');
    private tagApi = new Api('editor/tags');

    public async get(id: string) {
        const data = await this.api.get({
            id,
        });
        return this.convertToStoreData(data);
    }

    public async create(info: StoreData) {
        return this.api.post<UpdateResponse>(this.convertToRestData(info));
    }

    public async update(info: StoreData) {
        return this.api.post<UpdateResponse>(this.convertToRestData(info));
    }

    public async delete(id: string) {
        return await this.api.delete({
            id,
        });
    }

    public async search(search: SearchObject) {
        return this.searchApi.post<SearchResponse>(search);
    }

    public async recent() {
        return this.recentApi.get({
            id: '_',
        });
    }

    public async updateTags(updateTag: UpdateTagObject) {
        return this.tagApi.post(updateTag);
    }

    private convertToRestData(info: StoreData): Editor {
        return {
            id: info.id,
            accountId: '',
            name: info.name,
            width: info.width,
            height: info.height,
            palette: info.colorPalette.getColors().map((color) => {
                return { r: color.R, g: color.G, b: color.B, a: color.A };
            }),
            frames: info.frameInfos.map((frame) => {
                return {
                    id: frame.id,
                    layers: info.layerInfosMap[frame.id].map((layerInfo) => {
                        return {
                            id: layerInfo.id,
                            table: layerInfo.layer.getTable(),
                        };
                    }),
                };
            }),
            registeredAt: info.registeredAt,
            updatedAt: info.updatedAt,
            additional: {
                tags: info.additional.tags,
                thumbnail: info.additional.thumbnail,
            },
        };
    }

    private convertToStoreData(data: Editor): StoreData {
        return {
            id: data.id,
            name: data.name,
            width: data.width,
            height: data.height,
            colorPalette: new Palette(data.palette),
            layerInfosMap: Object.assign({}, ...data.frames.map((frame) => {
                return {
                    [frame.id]: frame.layers.map((layer) => {
                        return {
                            id: layer.id,
                            isDraw: true,
                            layer: new Layer({
                                width: layer.table[0].length,
                                height: layer.table.length,
                                table: layer.table,
                            }),
                        };
                    }),
                };
            })),
            frameInfos: data.frames.map((frame) => {
                return {
                    id: frame.id,
                    layer: new Layer(data.width, data.height),
                };
            }),
            registeredAt: data.registeredAt,
            updatedAt: data.updatedAt,
            additional: {
                tags: data.additional.tags,
                thumbnail: data.additional.thumbnail,
            },
        };
    }
}
export default new EditorApiRepository();
