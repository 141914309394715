import Api from './Api';

export interface SimpleNotice {
    id: string;
    title: string;
}

export interface Notice extends SimpleNotice {
    content: string;
    publishStartDatetime: string;
    publishEndDatetime: string;
    registeredAt: string;
}

class NoticeApiRepository {
    private api = new Api<Notice>('notice');
    private listApi = new Api<SimpleNotice[]>('notice/list');

    public async list() {
        return await this.listApi.get();
    }

    public async get(id: string) {
        const data = await this.api.get({
            id,
        });
        return data;
    }
}
export default new NoticeApiRepository();
