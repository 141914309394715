import { StoreData } from '@/store/editor';
import LocalForage from 'localforage';
import Palette from '@/logics/domain/palette/Palette';
import Layer from '@/logics/domain/layer/Layer';
import Auth from '@/logics/service/Auth';

const localForage = LocalForage.createInstance({
    driver: LocalForage.INDEXEDDB,
    name: 'drawten',
    storeName: 'editor',
    version: 1,
});

interface TemporaryLayerData {
    region: {
        left: number;
        top: number;
        width: number;
        height: number;
    };
    layer: Layer;
}

interface TemporarySettingData {
    showGrid: boolean;
    syncRefresh: boolean;
}

export type LocalStoreData = StoreData & { unsaved: boolean };

class EditorStateLocalRepository {

    public constructor(private store: LocalForage) {
    }

    /**
     * エディタ一時保存データの読み込み
     */
    public async load() {
        const auth = await Auth.getUser();
        if (!auth) {
            return null;
        }
        const data = await this.store.getItem(`${auth.id}/temporary`) as LocalStoreData;
        if (!data) {
            return null;
        }
        // JSONで保存されているのでデシリアライズする
        data.colorPalette = new Palette(data.colorPalette);
        for (const key of Object.keys(data.layerInfosMap)) {
            data.layerInfosMap[key].forEach((layerInfo) => {
                layerInfo.layer = new Layer(layerInfo.layer);
            });
        }
        for (const frameInfo of data.frameInfos) {
            frameInfo.layer = new Layer(frameInfo.layer);
        }
        return data;
    }

    /**
     * エディタ一時保存データの保存
     */
    public async save(state: LocalStoreData) {
        const auth = await Auth.getUser();
        if (!auth) {
            return null;
        }
        return this.store.setItem(`${auth.id}/temporary`, state);
    }

    /**
     * レイヤーの一時保存（クリップボードの代替）
     * @param layer レイヤー
     */
    public async saveTemporaryLayer(data: TemporaryLayerData) {
        const auth = await Auth.getUser();
        if (!auth) {
            return null;
        }
        return this.store.setItem(`${auth.id}/temporary/layer`, data);
    }

    /**
     * レイヤーの一時保存（クリップボードの代替）
     */
    public async loadTemporaryLayer() {
        const auth = await Auth.getUser();
        if (!auth) {
            return null;
        }
        const data = await this.store.getItem(`${auth.id}/temporary/layer`) as TemporaryLayerData;
        if (!data) {
            return null;
        } else {
            return {
                region: data.region,
                layer: new Layer(data.layer),
            };
        }
    }

    /**
     * 描画設定の一時保存
     */
    public async saveTemporarySetting(data: TemporarySettingData) {
        const auth = await Auth.getUser();
        if (!auth) {
            return null;
        }
        return this.store.setItem(`${auth.id}/temporary/setting`, data);
    }

    /**
     * 描画設定の一時保存
     */
    public async loadTemporarySetting() {
        const auth = await Auth.getUser();
        if (!auth) {
            return null;
        }
        const data = await this.store.getItem(`${auth.id}/temporary/setting`) as TemporarySettingData;
        return data || null;
    }
}

export default new EditorStateLocalRepository(localForage);
