import DrawStrategy from './DrawLayerStrategy';
import SquareStrokedStrategy from './DrawLayerSquareStrokedStrategy';
import Layer from '../../../domain/layer/Layer';
import { SpecialColor } from '../../../domain/palette/Palette';

export interface StrategyObserver {
    requestSelectRegion: (x: number, y: number, width: number, height: number, clipLayer?: Layer) => void;
}

export default class DrawLayerSelectRegionStrategy extends DrawStrategy {
    private origin!: Layer; // 描画開始初期レイヤー状態
    private startX!: number;
    private startY!: number;
    private squareStrokedStrategy!: SquareStrokedStrategy;

    public constructor(layer: Layer, private observer: StrategyObserver, private clipLayer?: Layer) {
        super(layer, 0);
        this.squareStrokedStrategy = new SquareStrokedStrategy(this.layer, SpecialColor.SelectColor.index);
    }

    public drawStart(x: number, y: number) {
        this.origin = this.layer.clone();
        this.startX = x;
        this.startY = y;
        this.squareStrokedStrategy.drawStart(this.startX, this.startY);
    }

    public drawMiddle(x: number, y: number) {
        if (!this.origin) {
            return;
        }
        this.squareStrokedStrategy.drawMiddle(x, y);
    }

    public drawEnd(x: number, y: number) {
        if (!this.origin) {
            return;
        }
        this.squareStrokedStrategy.drawEnd(x, y);
        this.layer.overwriteFrom(this.origin);
        this.observer.requestSelectRegion(Math.min(this.startX, x), Math.min(this.startY, y),
            Math.abs(x - this.startX) + 1, Math.abs(y - this.startY) + 1, this.clipLayer);
    }

    public changeLayer(layer: Layer) {
        this.layer = layer;
        this.squareStrokedStrategy.changeLayer(layer);
    }

    public necessaryUndo() {
        return false;
    }
}
