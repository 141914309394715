
import { Component, Prop } from 'vue-property-decorator';
import ToolButton from '@/components/organisms/tool-box/ToolButton.vue';
import { StrategyType } from '@/logics/service/layer/strategy/DrawLayerStrategyFactory';

@Component
export default class ToolButtonSquareStroked extends ToolButton {
    protected type: StrategyType = StrategyType.SquareStroked;
    protected iconSrc: string = require('@/assets/tool-box/square-stroked.svg');

    protected mounted() {
        this.tooltip = this.$t('editor.toolButtons.tooltip.squareStroked').toString();
    }
}
