import DrawStrategy from './DrawCanvasStrategy';
import Canvas from '../../../domain/canvas/Canvas';
import { default as Pixel, PIXEL_UNIT } from '@/logics/domain/canvas/Pixel';

/**
 * グリッド描画処理
 */
export default class DrawGridStrategy extends DrawStrategy {
    public constructor(canvas: Canvas) {
        super(canvas);
    }

    public draw(unit: number) {
        const bytes = new Uint8ClampedArray(this.canvas.width * this.canvas.height * PIXEL_UNIT);
        // 縦方向のライン
        const xSkip = unit * PIXEL_UNIT;
        for (let x = 0; x < bytes.length; x += xSkip) {
            Pixel.setA(bytes, x, 0x33);
        }
        // 横方向のライン
        const ySkip = xSkip * this.canvas.width;
        const bufferWidth =  this.canvas.width * PIXEL_UNIT;
        for (let y = 0; y < bytes.length; y += ySkip) {
            for (let x = 0; x < bufferWidth; x += PIXEL_UNIT) {
                Pixel.setA(bytes, y + x, 0x33);
            }
        }
        this.canvas.draw(bytes);
    }
}
