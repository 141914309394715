// 画面上でのキーイベントを通知する
// クラス自体のexportはしない（シングルトンとして利用）
class GlobalKeyEventHandler {
    private observers: Array<{ el: Element, codes: number[], notify: () => void }> = [];
    private keyState: boolean[] = [];

    public addObserver(el: Element, codes: number[], notify: () => void) {
        this.observers.push({
            el,
            codes,
            notify,
        });
    }

    /**
     * 登録したオブザーバーのキー押下処理である場合に通知する
     * @param e キーボードイベント
     */
    public notify(e: KeyboardEvent) {
        this.keyState[e.keyCode] = true;
        // DOMツリーから削除されているものは消しておく
        this.observers = this.observers.filter((observer) => {
            return document.body.contains(observer.el);
        });
        this.observers.forEach((observer) => {
            if (observer.codes.every((code) => this.keyState[code])) {
                observer.notify();
                e.preventDefault();
            }
        });
    }

    public releaseKey(e: KeyboardEvent) {
        this.keyState[e.keyCode] = false;
    }
}
const globalKeyEventHandler = new GlobalKeyEventHandler();

// イベントの監視
window.addEventListener('load', () => {
    document.body.addEventListener('keydown', (e: Event) => {
        globalKeyEventHandler.notify(e as KeyboardEvent);
    });
    document.body.addEventListener('keyup', (e: Event) => {
        globalKeyEventHandler.releaseKey(e as KeyboardEvent);
    });
});

export default globalKeyEventHandler;
