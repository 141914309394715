import DrawStrategy from './DrawLayerStrategy';
import Layer from '../../../domain/layer/Layer';

export interface StrategyObserver {
    requestChangeColorIndex: (index: number) => void;
}

/**
 * スポイト描画処理
 */
export default class DrawLayerSyringeStrategy extends DrawStrategy {

    public constructor(layer: Layer, private observer: StrategyObserver) {
        super(layer, 0);
    }

    public drawStart(x: number, y: number) {
        // 不要
    }

    public drawMiddle(x: number, y: number) {
        // 不要
    }

    public drawEnd(x: number, y: number) {
        this.observer.requestChangeColorIndex(this.layer.get(x, y));
    }
}
