import { Module } from 'vuex';

export interface AccountState {
    type: AccountType;
    email: string;
    settlement?: Settlement;
}

export interface Settlement {
    customerId: string;
    subscriptionId?: string;
}

export enum AccountType {
    Free = 'Free', // 無料
    Premium = 'Premium', // 有料
}

const AccountModule: Module<AccountState, any> = {
    namespaced: true,
    state: {
        type: AccountType.Free,
        email: '',
    },
    mutations: {
        update: (state, account: AccountState) => {
            state.type = account.type;
            state.email = account.email;
            if (account.settlement) {
                state.settlement = account.settlement;
            }
        },
    },
    getters: {
        getType: (state) => {
            return state.type;
        },
        isFree: (state) => {
            return state.type === AccountType.Free;
        },
        isPremium: (state) => {
            return state.type === AccountType.Premium;
        },
        hasSettlementAccount: (state) => {
            return !!(state.settlement && state.settlement.customerId);
        },
        getSettlementCustomerId: (state) => {
            return state.settlement && state.settlement.customerId || null;
        },
        getSettlementSubscriptionId: (state) => {
            return state.settlement && state.settlement.subscriptionId || null;
        },
    },
};

export default AccountModule;
