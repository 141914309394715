






























































import { Vue, Component, Prop } from 'vue-property-decorator';
import Auth from '@/logics/service/Auth';
import AccountApiRepository from '@/logics/repository/api/AccountApiRepository';
import GlobalError from '@/logics/domain/error/GlobalError';
import ValidatorBuilder from '@/logics/util/ValidatorBuilder';

@Component
export default class AddAccountForm extends Vue {
    private emailField: string = '';
    private passwordField: string = '';
    private confirmPasswordField: string = '';
    private agreeTermsField = false;
    private succeedAddAccount = false;
    private termsUrl?: string = '';

    private get emailRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required',
                { label: this.$t('form.addAccountForm.labels.mailAddress') }))
            .emailFormat(this.$t('errors.validation.malformed',
                { label: this.$t('form.addAccountForm.labels.mailAddress') }))
            .build();
    }

    private get passwordRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required',
                { label: this.$t('form.addAccountForm.labels.password') }))
            .password(this.$t('errors.validation.password'))
            .build();
    }

    private get confirmPasswordRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required',
                { label: this.$t('form.addAccountForm.labels.confirmPassword') }))
            .equals(this.passwordField, this.$t('errors.validation.mismatch', {
                    value1: this.$t('form.addAccountForm.labels.password'),
                    value2: this.$t('form.addAccountForm.labels.confirmPassword'),
                }))
            .build();
    }

    private get agreeTermsRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required',
                { label: this.$t('form.addAccountForm.labels.agreeTerms') }))
            .build();
    }

    private mounted() {
        this.termsUrl = process.env.VUE_APP_TERMS_URL;
    }

    private cancel() {
        this.$emit('cancel');
    }

    private async submit() {
        const form = this.$refs.addAccountForm as any;
        if (!form.validate()) {
            return;
        }
        try {
            this.$store.dispatch('startLoading');
            // firebaseへ登録
            const registeredAt = await Auth.addAccount(this.emailField, this.passwordField);
            // サーバーへUID登録
            await AccountApiRepository.create({
                registeredAt,
            });
            this.succeedAddAccount = true;
        } catch (e) {
            if (e.code === 'auth/email-already-in-use') {
                this.$store.dispatch('throwError',
                    new GlobalError(this.$t('errors.exception.auth.alreadyRegistered').toString()));
            } else {
                this.$store.dispatch('throwError',
                    new GlobalError(this.$t('errors.exception.auth.addAccountError').toString()));
            }
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }
}
