import { loadStripe, Stripe } from '@stripe/stripe-js';

const STRIPE_PUB_KEY = process.env.VUE_APP_STRIPE_PUB_KEY;

class Settlement {
    private loadedStripe: Stripe | null = null;
    private get stripe() {
        return this.loadedStripe as Stripe;
    }

    constructor() {
        loadStripe(STRIPE_PUB_KEY as string).then((result: Stripe | null) => {
            this.loadedStripe = result;
        });
    }

    public async redirectToSettlement(sessionId: string) {
        this.stripe.redirectToCheckout({
            sessionId,
        });
    }
}
export default new Settlement();
