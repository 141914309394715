import Vue from 'vue';
import Vuex from 'vuex';
import EditorModule from './editor';
import AccountModule from './account';
import GlobalAlert from '@/logics/domain/common/GlobalAlert';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';
import GlobalError from '@/logics/domain/error/GlobalError';

Vue.use(Vuex);

interface GlobalState {
    loading: boolean;
    progress: number | null;
    hasHeader: boolean;
    message: GlobalMessage| null;
    alert: GlobalAlert | null;
    error: GlobalError | null;
}

export default new Vuex.Store<GlobalState>({
    state: {
        loading: false,
        progress: null,
        hasHeader: false,
        alert: null,
        message: null,
        error: null,
    },
    mutations: {
        loading: (state, loading) => {
            state.loading = loading;
        },
        progress: (state, progress) => {
            state.progress = progress;
        },
        hasHeader: (state, enable) => {
            state.hasHeader = enable;
        },
        setAlert: (state, alert: GlobalAlert) => {
            state.alert = alert;
        },
        setMessage: (state, message: GlobalMessage) => {
            state.message = message;
        },
        setError: (state, error: GlobalError) => {
            state.error = error;
        },
    },
    actions: {
        startLoading: (state) => {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
            state.commit('loading', true);
        },
        stopLoading: (state) => {
            state.commit('loading', false);
        },
        showProgress: (state, progress) => {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
            state.commit('progress', progress);
        },
        showAlert: (state, alert: GlobalAlert) => {
            state.commit('setAlert', alert);
        },
        closeAlert: (state) => {
            state.commit('setAlert', null);
        },
        showMessage: (state, message: GlobalMessage) => {
            state.commit('setMessage', message);
        },
        throwError: (state, error: GlobalError) => {
            state.commit('setError', error);
        },
    },
    modules: {
        account: AccountModule,
        editor: EditorModule,
    },
});
