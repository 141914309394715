





import { Vue, Component, Prop } from 'vue-property-decorator';
import IconImage from '@/components/molecules/IconImage.vue';

@Component({
    components: {
        IconImage,
    },
})
export default class CircleIconButton extends Vue {
    @Prop() private src!: string;
    @Prop({ default: 24 }) private width!: number;
    @Prop({ default: 24 }) private height!: number;
    @Prop({ default: null }) private color!: string;

    private onclick(e: Event) {
        this.$emit('click', e);
    }
}
