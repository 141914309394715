export default class UndoRedoManager<T> {
    private undoStack: T[] = [];
    private redoStack: T[] = [];

    public addUndo(pushData: T) {
        this.undoStack.push(pushData);
    }

    public addRedo(pushData: T) {
        this.redoStack.push(pushData);
    }

    public clearUndo() {
        this.undoStack = [];
    }

    public clearRedo() {
        this.redoStack = [];
    }

    public clear() {
        this.clearUndo();
        this.clearRedo();
    }

    public canUndo() {
        return this.undoStack.length > 0;
    }

    public canRedo() {
        return this.redoStack.length > 0;
    }

    public redo(): T {
        return this.redoStack.pop()!;
    }

    public undo() {
        return this.undoStack.pop()!;
    }
}
