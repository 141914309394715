













































































import { Vue, Component } from 'vue-property-decorator';
import InputSearch from '@/components/molecules/form/InputSearch.vue';
import RecentList from '@/components/organisms/mypage/RecentList.vue';
import SearchList from '@/components/organisms/mypage/SearchList.vue';
import NoticeList from '@/components/organisms/notice/NoticeList.vue';
import { default as SettingCanvasForm, FormResult as NewCanvasParams } from '@/components/organisms/form/SettingCanvasForm.vue';
import { default as SettingTagForm , FormResult as SettingTagParams } from '@/components/organisms/form/SettingTagForm.vue';
import { default as Api, SearchInfo } from '@/logics/repository/api/EditorApiRepository';
import Auth from '@/logics/service/Auth';
import { AlertType, default as GlobalAlert } from '@/logics/domain/common/GlobalAlert';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import TagList from '@/components/organisms/tag/TagList.vue';
import { Tag } from '@/logics/repository/api/TagApiRepository';
import EditorStateLocalRepository from '@/logics/repository/local/EditorStateLocalRepository';
import { default as NoticeApiRepository, SimpleNotice } from '@/logics/repository/api/NoticeApiRepository';

@Component({
    components: {
        InputSearch,
        RecentList,
        SearchList,
        NoticeList,
        SettingCanvasForm,
        SettingTagForm,
        CircleIconButton,
        TagList,
    },
})
export default class MyPageView extends Vue {
    private static readonly PAGE_UNIT = 10;

    private srcTag = require('@/assets/layer/tag.svg');

    private showNewDialog = false;
    private showEditTagDialog = false;
    private showSearchTagDialog = false;
    private recentInfos: SearchInfo[] = [];
    private searchInfos: SearchInfo[] = [];
    private notices: SimpleNotice[] = [];

    private editTagInfo!: { id: string, tags: string[] };
    private searchTags: Tag[] = [];
    private searchKeyword: string = '';
    private searchPage: number = 1;
    private pageLength: number = 1;
    private existUnsavedData = false;

    private get searchTagEnableColor() {
        return this.searchTagIds.length > 0 ? '#b9ffc8' : '#ffffff';
    }

    private get searchTagIds() {
        return this.searchTags.map((tag: Tag) => tag.id);
    }

    private async mounted() {
        this.refreshRecent();
        const temporaryData = await EditorStateLocalRepository.load();
        if (temporaryData) {
            this.existUnsavedData = !!temporaryData.unsaved;
        }
        this.notices = await NoticeApiRepository.list();
    }

    private confirmUnsavedCallback(run: () => void) {
        if (this.existUnsavedData) {
            this.$store.dispatch('showMessage', new GlobalMessage({
                title: this.$t('mypage.unsavedDataConfirm.title').toString(),
                message: this.$t('mypage.unsavedDataConfirm.message').toString(),
                okCallback: () => {
                    run();
                },
            }));
        } else {
            run();
        }
    }

    private clickNewCanvas(e: Event, activator: { click: (e: Event) => void }) {
        this.confirmUnsavedCallback(() => {
            activator.click(e);
        });
    }

    private async refreshRecent() {
        try {
            this.$store.dispatch('startLoading');
            const response = await Api.recent();
            this.recentInfos = response.infos;
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }

    private async refreshSearch() {
        this.search(this.searchKeyword, this.searchTagIds, MyPageView.PAGE_UNIT, 1);
    }

    private async submitSearch(keyword: string) {
        this.searchKeyword = keyword;
        this.search(this.searchKeyword, this.searchTagIds, MyPageView.PAGE_UNIT, 1);
        this.searchPage = 1;
    }

    private async search(keyword: string, tagIds: string[], unit: number, page: number) {
        try {
            this.$store.dispatch('startLoading');
            const user = await Auth.getUser();
            const response =  await Api.search({
                accountId: user!.id,
                keyword,
                tags: tagIds,
                pageUnit: unit,
                page,
            });
            this.searchInfos = response.infos;
            this.pageLength = Math.ceil(response.totalCount / MyPageView.PAGE_UNIT);
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }

    private transitionCanvasAsUnsaved() {
        this.$router.push({
            name: 'editor',
        });
    }

    private createNewCanvas(formResult: NewCanvasParams) {
        this.$router.push({
            name: 'editor',
            params: {
                name: formResult.name,
                width: `${formResult.width}`,
                height: `${formResult.height}`,
                tagIds: JSON.stringify(formResult.tagIds),
            },
        });
    }

    private transitionCanvas(id: string) {
        this.confirmUnsavedCallback(() => {
            this.$router.push({
                name: 'editor',
                params: {
                    id,
                },
            });
        });
    }

    private async copyCanvas(id: string) {
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('mypage.copyConfirm.title').toString(),
            message: this.$t('mypage.copyConfirm.message').toString(),
            okCallback: async () => {
                this.$nextTick(() => {
                    this.confirmUnsavedCallback(() => {
                        this.$router.push({
                            name: 'editor',
                            params: {
                                id,
                                asCopy: 'true',
                            },
                        });
                    });
                });
            },
        }));
    }

    private async removeCanvas(id: string) {
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('mypage.removeConfirm.title').toString(),
            message: this.$t('mypage.removeConfirm.message').toString(),
            okCallback: async () => {
                try {
                    this.$store.dispatch('startLoading');
                    await Api.delete(id);
                    this.$store.dispatch('showAlert', new GlobalAlert(
                        AlertType.Success,
                        this.$t('mypage.removeAlert.success').toString(),
                        2000,
                    ));
                    this.refreshRecent();
                    if (this.searchInfos.length > 0 || this.searchKeyword || this.searchTagIds.length > 0) {
                        this.refreshSearch();
                    }
                } catch {
                    this.$store.dispatch('showAlert', new GlobalAlert(
                        AlertType.Error,
                        this.$t('mypage.removeAlert.failure').toString(),
                        2000,
                    ));
                } finally {
                    this.$store.dispatch('stopLoading');
                }
            },
        }));
    }

    private async showEditTag(args: {id: string, tags: string[]}) {
        this.editTagInfo = args;
        this.showEditTagDialog = true;
    }

    private async submitEditTag(params: SettingTagParams) {
        try {
            this.$store.dispatch('startLoading');
            const user = await Auth.getUser();
            const tagIds = params.tags.map((tag) => tag.id);
            await Api.updateTags({
                id: this.editTagInfo.id,
                accountId: user!.id,
                tags: tagIds,
            });
            // ローカル表示中データの更新
            this.recentInfos.forEach((info) => {
                if (info.id === this.editTagInfo.id) {
                    info.tags = tagIds;
                }
            });
            // 検索一覧は変更による結果が変わる可能性があるので取り直す
            if (this.searchInfos.length > 0 || this.searchKeyword || this.searchTagIds.length > 0) {
                this.refreshSearch();
            }
        } finally {
            this.$store.dispatch('stopLoading');
            this.showEditTagDialog = false;
        }
    }

    private async showSearchTag() {
        this.showSearchTagDialog = true;
    }

    private async submitSearchTag(params: SettingTagParams) {
        this.searchTags = params.tags;
        await this.search(this.searchKeyword, this.searchTagIds, MyPageView.PAGE_UNIT, 1);
        this.showSearchTagDialog = false;
        this.searchPage = 1;
    }

    private async changePager(page: number) {
        await this.search(this.searchKeyword, this.searchTagIds, MyPageView.PAGE_UNIT, page);
    }
}
