












































import { Vue, Component } from 'vue-property-decorator';
import EditAccountForm from '@/components/organisms/form/EditAccountForm.vue';
import LoginForm from '@/components/organisms/form/LoginForm.vue';
import { FormResult } from '@/components/organisms/form/EditAccountForm.vue';
import Auth from '@/logics/service/Auth';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';
import GlobalError from '@/logics/domain/error/GlobalError';
import AccountApiRepository from '@/logics/repository/api/AccountApiRepository';
import { AccountType } from '@/store/account';
import SettlementApiRepository from '@/logics/repository/api/SettlementApiRepository';

@Component({
    components: {
        EditAccountForm,
        LoginForm,
    },
})
export default class AccountInformation extends Vue {
    private showLoginDialogForEdit = false;
    private showLoginDialogForWithdraw = false;
    private showEditDialog = false;
    private formResult!: FormResult;

    private get typeLabel() {
        return this.type === AccountType.Premium
            ? this.$t('account.plan.premium').toString()
            : this.$t('account.plan.free').toString();
    }

    private get type() {
        return this.$store.state.account.type;
    }

    private get email() {
        return this.$store.state.account.email;
    }

    private get hasSettlementAccount() {
        return this.$store.getters['account/hasSettlementAccount'];
    }

    private get settlementCustomerId() {
        return this.$store.state.account.settlement.customerId;
    }

    private close() {
        this.$emit('close');
    }

    private async logout() {
        try {
            this.$store.dispatch('startLoading');
            await Auth.logout();
            this.$router.push({
                name: 'login',
            });
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }

    private async withdraw() {
        try {
            this.$store.dispatch('startLoading');
            if (this.hasSettlementAccount) {
                await SettlementApiRepository.deleteCustomer(this.settlementCustomerId);
            }
            await AccountApiRepository.delete();
            await Auth.withdraw();
            this.$router.push({
                name: 'withdraw-complete',
            });
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }

    private async succeedInputEdit(result: FormResult) {
        // 更新前に再認証させる
        this.showEditDialog = false;
        this.showLoginDialogForEdit = true;
        this.formResult = result;
    }

    private async updateAccount() {
        try {
            this.$store.dispatch('startLoading');
            if (this.email !== this.formResult.email) {
                await Auth.updateEmail(this.formResult.email);
                if (this.hasSettlementAccount) { // 決済システムに変更を合わせる
                    await SettlementApiRepository.changeCustomerEmail(
                        this.settlementCustomerId, this.formResult.email);
                }
                this.$store.commit('account/update', {
                    type: this.type,
                    email: this.formResult.email,
                    settlement: this.$store.state.account.settlement,
                });
            }
        } catch (e) {
            if (e.code === 'auth/email-already-in-use') {
                this.$store.dispatch('throwError',
                    new GlobalError(this.$t('errors.exception.auth.alreadyRegistered').toString()));
            } else {
                this.$store.dispatch('throwError',
                    new GlobalError(this.$t('errors.exception.auth.updateAccountError').toString()));
            }
        } finally {
            this.showLoginDialogForEdit = false;
            this.$store.dispatch('stopLoading');
        }
    }

    private async cancelForEdit() {
        this.showLoginDialogForEdit = false;
    }

    private async confirmWithdraw() {
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('account.headerContextMenu.withdrawConfirm.title').toString(),
            message: this.$t('account.headerContextMenu.withdrawConfirm.message').toString(),
            okCallback: async () => {
                this.showLoginDialogForWithdraw = true;
            },
        }));
    }

    private async cancelForWithdraw() {
        this.showLoginDialogForWithdraw = false;
    }
}
