import RedrawCanvasStrategy from './RedrawCanvasStrategy';
import DrawGridStrategy from './DrawGridStrategy';
import DrawAuxiliaryGridStrategy from './DrawAuxiliaryGridStrategy';
import DrawCanvasStrategy from './DrawCanvasStrategy';

export const enum StrategyType {
    Redraw = 'Redraw',
    Grid = 'Grid',
    AuxiliaryGrid = 'AuxiliaryGrid',
}

class DrawCanvasStrategyFactory {

    public create(type: StrategyType, ...args: any[]): DrawCanvasStrategy {
        switch (type) {
            case StrategyType.Redraw:
                return new RedrawCanvasStrategy(args[0], args[1], args[2]);
            case StrategyType.Grid:
                return new DrawGridStrategy(args[0]);
            case StrategyType.AuxiliaryGrid:
                return new DrawAuxiliaryGridStrategy(args[0], args[1]);
            default:
                throw new Error(); // 来ないはず
        }
    }
}

export default new DrawCanvasStrategyFactory();
