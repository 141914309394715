























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import LayerHoverMenu from '@/components/organisms/layer/LayerHoverMenu.vue';
import LayerDrawer from '@/components/molecules/LayerDrawer.vue';
import Layer from '@/logics/domain/layer/Layer';
import Palette from '@/logics/domain/palette/Palette';

@Component({
    components: {
        LayerHoverMenu,
        LayerDrawer,
    },
})
export default class LayerPreview extends Vue {
    @Prop({ required: true }) private index!: number;
    @Prop({ required: true }) private toplayer!: boolean;
    @Prop({ required: true }) private bottomlayer!: boolean;
    @Prop({ required: true }) private draw!: number;
    @Prop({ required: true }) private size!: number;
    @Prop({ required: true }) private layer!: Layer;
    @Prop({ required: true }) private palette!: Palette;
    private isActive = false;
    private isHover = false;

    public refresh() {
        (this.$refs.preview as LayerDrawer).refresh();
    }

    public refreshSize(width: number, height: number) {
        (this.$refs.preview as LayerDrawer).refreshSize(width, height);
    }

    public activate() {
        this.isActive = true;
    }

    public inactivate() {
        this.isActive = false;
    }

    private onmouseover() {
        this.isHover = true;
    }

    private onmouseleave() {
        this.isHover = false;
    }

    private select() {
        this.$emit('select', this.index);
    }

    private front() {
        this.$emit('front', this.index);
    }

    private back() {
        this.$emit('back', this.index);
    }

    private copy() {
        this.$emit('copy', this.index);
    }

    private close() {
        this.$emit('close', this.index);
    }

    private toHide() {
        this.$emit('to-hide', this.index);
    }

    private toShow() {
        this.$emit('to-show', this.index);
    }

    private mounted() {
        this.refresh();
    }
}
