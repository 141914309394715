






































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ValidatorBuilder from '@/logics/util/ValidatorBuilder';
import { default as Api, Tag } from '@/logics/repository/api/TagApiRepository';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';

export interface FormResult {
    tags: Tag[];
}

@Component({
    components: {
        CircleIconButton,
    },
})
export default class SettingTagForm extends Vue {
    @Prop({ default: false }) private forSelect!: boolean;
    @Prop({ default: () => [] }) private tagIds!: string[];
    private srcRight = require('@/assets/layer/arrow-right.svg');
    private srcLeft = require('@/assets/layer/arrow-left.svg');
    private srcRemove = require('@/assets/layer/close.svg');

    private items: Tag[] = [];
    private masterItems: Tag[] = [];
    private selectIndex: number | null = null;

    private tagField = '';
    private editTagLabel = '';

    private get selectedIndex() {
        if (typeof this.selectIndex === 'number') {
            return this.selectIndex;
        } else {
            return null;
        }
    }

    private get tagRules() {
        return new ValidatorBuilder()
            .length(30, this.$t('errors.validation.length', {
                    label: this.$t('form.settingTagForm.labels.name'),
                    length: 30,
                }))
            .build();
    }

    private async mounted() {
        this.editTagLabel = this.$t('dialog.button.shortCreate').toString();
        try {
            this.$store.dispatch('startLoading');
            this.masterItems = await Api.list();
            this.items.push(...this.masterItems.filter((i) => {
                return this.tagIds.filter((id) => i.id === id).length > 0;
            }));
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }

    private changeSelectItem() {
        // selectIndexを確定させるためにnextTickを挟む
        this.$nextTick(() => {
            if (this.selectedIndex !== null) {
                this.editTagLabel = this.$t('dialog.button.shortUpdate').toString();
                this.tagField = this.masterItems[this.selectedIndex].name;
            } else {
                this.editTagLabel = this.$t('dialog.button.shortCreate').toString();
                this.tagField = '';
            }
        });
    }

    private takeOffTag(index: number) {
        this.items.splice(index, 1);
    }

    private takeOnTag(index: number) {
        if (this.items.filter((i) => i.id === this.masterItems[index].id).length === 0) {
            this.items.push(this.masterItems[index]);
        }
    }

    private removeTag(index: number) {
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('form.settingTagForm.removeConfirm.title').toString(),
            message: this.$t('form.settingTagForm.removeConfirm.message').toString(),
            okCallback: async () => {
                const tag = this.masterItems[index];
                try {
                    this.$store.dispatch('startLoading');
                    await Api.delete(tag.id);
                    this.masterItems.splice(index, 1);
                } finally {
                    this.$store.dispatch('stopLoading');
                }
            },
        }));
    }

    private async editTag() {
        const form = this.$refs.tagForm as any;
        if (this.tagField.length === 0 || !form.validate()) {
            return;
        }
        try {
            this.$store.dispatch('startLoading');
            if (this.selectedIndex === null) {
                this.masterItems.push(await Api.create(this.tagField));
                this.tagField = '';
            } else {
                const orgItem = this.masterItems[this.selectedIndex];
                const updatedTag = await Api.update(orgItem.id, this.tagField);
                orgItem.name = updatedTag.name;
            }
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }

    private cancel() {
        this.$emit('cancel');
    }

    private submit() {
        this.$emit('submit', {
            tags: this.items.slice(),
        });
    }
}
