




























import { Vue, Component } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import { default as SavePaletteForm, FormResult as SaveFormResult } from '@/components/organisms/form/SavePaletteForm.vue';
import { default as LoadPaletteForm, FormResult as LoadFormResult } from '@/components/organisms/form/LoadPaletteForm.vue';
import GlobalMouseEventHandler from '@/logics/util/handler/GlobalMouseEventHandler';

@Component({
    components: {
        CircleIconButton,
        SavePaletteForm,
        LoadPaletteForm,
    },
})
export default class PaletteBoardMenu extends Vue {
    private src: string = require('@/assets/palette/hamburger.svg');
    private showMenu = false;
    private showSaveDialog = false;
    private showLoadDialog = false;

    private mounted() {
        // コンテキスト外クリック時の制御
        // （単純にフラグで非表示にするとダイアログ毎消えるのでダイアログ表示中は消さないようにする）
        const el = this.$refs.paletteBoardMenu as Element;
        GlobalMouseEventHandler.addObserver(el, (e) => {
            setTimeout(() => { // オーバーレイが出るまで少し時間差があるので待つ
                const target = e.target as Element;
                const overlayEl = document.querySelector('.v-overlay--active') as Element;
                if (!el.contains(target) && !overlayEl) {
                    this.showMenu = false;
                }
            }, 200);
        });
    }

    private savePalette(result: SaveFormResult) {
        this.showSaveDialog = false;
        this.showMenu = false;
        this.$emit('save', result.name);
    }

    private loadPalette(result: LoadFormResult) {
        this.showLoadDialog = false;
        this.showMenu = false;
        this.$emit('load', result.palette);
    }
}
