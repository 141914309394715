














import { Vue, Component, Prop } from 'vue-property-decorator';
import { SearchInfo } from '@/logics/repository/api/EditorApiRepository';
import ListItem from '@/components/organisms/mypage/ListItem.vue';

@Component({
    components: {
        ListItem,
    },
})
export default class SearchList extends Vue {
    @Prop({ default: () => [] }) private searchInfos!: SearchInfo[];
    @Prop({ default: 100 }) private width!: number;
    @Prop({ default: 100 }) private height!: number;

    private onclickItem(id: string) {
        this.$emit('select', id);
    }

    private onclickCopy(id: string) {
        this.$emit('copy', id);
    }

    private onclickRemove(id: string) {
        this.$emit('remove', id);
    }

    private onclickTag(args: {id: string, tags: string[]}) {
        this.$emit('tag', args);
    }
}
