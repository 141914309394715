

























import { Vue, Component, Prop } from 'vue-property-decorator';
import ValidatorBuilder from '@/logics/util/ValidatorBuilder';

export interface FormResult {
    name: string;
}

@Component
export default class SavePaletteForm extends Vue {
    private nameField: string = '';

    private get nameRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required',
                { label: this.$t('form.savePaletteForm.labels.name') }))
            .build();
    }

    private cancel() {
        this.nameField = '';
        this.$emit('cancel');
    }

    private submit() {
        const form = this.$refs.savePaletteForm as any;
        if (!form.validate()) {
            return;
        }
        this.$emit('submit', {
            name: this.nameField,
        } as FormResult);
    }
}
