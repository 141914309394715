import { render, staticRenderFns } from "./UndoButton.vue?vue&type=template&id=5b01f7ea&scoped=true&"
import script from "./UndoButton.vue?vue&type=script&lang=ts&"
export * from "./UndoButton.vue?vue&type=script&lang=ts&"
import style0 from "./UndoButton.vue?vue&type=style&index=0&id=5b01f7ea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b01f7ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
