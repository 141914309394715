












import { Vue, Component } from 'vue-property-decorator';
import LoginForm from '@/components/organisms/form/LoginForm.vue';
import AddAccountForm from '@/components/organisms/form/AddAccountForm.vue';
import ResetPasswordForm from '@/components/organisms/form/ResetPasswordForm.vue';

enum CurrentForm {
    Login,
    AddAccount,
    ResetPassword,
}

@Component({
    components: {
        LoginForm,
        AddAccountForm,
        ResetPasswordForm,
    },
})
export default class LoginView extends Vue {
    private CurrentForm = CurrentForm;
    private currentForm = this.CurrentForm.Login;

    private addAccount() {
        this.currentForm = this.CurrentForm.AddAccount;
    }

    private cancelAddAccount() {
        this.currentForm = this.CurrentForm.Login;
    }

    private resetPassword() {
        this.currentForm = this.CurrentForm.ResetPassword;
    }

    private cancelResetPassword() {
        this.currentForm = this.CurrentForm.Login;
    }

    private succeedLogin() {
        this.$router.push({ name: 'mypage' });
    }
}
