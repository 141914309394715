import Color from './Color';

export const SpecialColor = {
    SelectColor: {
        index: -1,
        color: Color.fromHex('#000000'),
    },
    TemporaryColor: { // 作業用に一時的に使用するための色（即時適切な色に置換することを想定）
        index: -2,
        color: Color.fromHex('#FFFFFF'),
    },
    getColor: (index: number) => {
        if (SpecialColor.SelectColor.index === index) {
            return SpecialColor.SelectColor.color;
        } else if (SpecialColor.TemporaryColor.index === index) {
            return SpecialColor.TemporaryColor.color;
        } else {
            return undefined;
        }
    },
};

/**
 * パレット
 */
export default class Palette {
    public readonly id?: string; // 保存済みパレットの場合に存在する
    public readonly name?: string; // 保存済みパレットの場合に存在する
    private data: Array<{ color: Color }> = [];

    public constructor(json?: any)
    public constructor(arg?: Array<{ r: number, g: number, b: number, a: number }>) {
        if (!arg) {
            this.data = [...Array(256)].map((v, i) => {
                return { color: Color.fromHex('#000000') };
            });
            this.data[0] = { color: Color.fromHex('#FFFFFF') };
        } else if (arg instanceof Array) {
            this.data = arg.map((colorData) => {
                return {
                    color: new Color(
                        colorData.r,
                        colorData.g,
                        colorData.b,
                        colorData.a,
                    ),
                };
            });
        } else {
            const json = arg as Palette;
            this.id = json.id;
            this.name = json.name;
            this.data = json.data.map((colorData: { color: any }) => {
                const colorJson = colorData.color;
                colorData.color = new Color(
                    colorJson.r,
                    colorJson.g,
                    colorJson.b,
                    colorJson.a,
                );
                return colorData;
            });
        }
    }

    public getColors(): Color[] {
        return this.data.map((d) => d.color);
    }

    public getColor(index: number) {
        return SpecialColor.getColor(index) || this.data[index].color;
    }

    public changeColor(index: number, color: any): void;
    public changeColor(index: number, color: any) {
        if (color instanceof Color) {
            this.data[index].color = color;
        } else {
            this.data[index].color = Color.fromHex(color);
        }
    }
}
