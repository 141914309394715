import Api from './Api';
import { v4 as UUID } from 'uuid';

export interface Tag { // サーバー側形式
    id: string;
    accountId: string;
    name: string;
}

class TagApiRepository {
    private api = new Api<Tag>('tag');
    private manyApi = new Api('tag/many');
    private listApi = new Api<Tag[]>('tag/list');

    public async get(id: string) {
        const data = await this.api.get({
            id,
        });
        return this.convertToStoreData(data);
    }

    public async create(name: string) {
        const newTag = this.convertToRestData(UUID(), name);
        await this.api.post(newTag);
        return newTag;
    }

    public async update(id: string, name: string) {
        const updateTag = this.convertToRestData(id, name);
        await this.api.post(updateTag);
        return updateTag;
    }

    public async delete(id: string) {
        return await this.api.delete({
            id,
        });
    }

    public async many(ids: string[]) {
        const response: Tag[] = await this.manyApi.post({
            ids,
        });
        return response.map((res) => this.convertToStoreData(res));
    }

    public async list() {
        const response = await this.listApi.get();
        return response.map((res) => this.convertToStoreData(res));
    }

    private convertToRestData(id: string, name: string): Tag {
        return {
            id,
            accountId: '',
            name,
        };
    }

    private convertToStoreData(data: Tag) {
        return data;
    }
}
export default new TagApiRepository();
