


import GlobalMessage from '@/logics/domain/common/GlobalMessage';
import { Vue, Component } from 'vue-property-decorator';
import Router from '@/router';

@Component
export default class WithdrawCompleteView extends Vue {

    private mounted() {
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('form.withdrawForm.title').toString(),
            message: this.$t('form.withdrawForm.message').toString(),
            okOnly: true,
            okCallback: () => {
                Router.push({
                    name: 'login',
                });
            },
        }));
    }
}
