


















import { Vue, Component, Prop } from 'vue-property-decorator';
import { SearchInfo } from '@/logics/repository/api/EditorApiRepository';
import ListItemHoverMenu from './ListItemHoverMenu.vue';

@Component({
    components: {
        ListItemHoverMenu,
    },
})
export default class ListItem extends Vue {
    @Prop() private info!: SearchInfo;
    @Prop({ default: 100 }) private width!: number;
    @Prop({ default: 100 }) private height!: number;
    private isHover = false;

    private onmouseover() {
        this.isHover = true;
    }

    private onmouseleave() {
        this.isHover = false;
    }

    private click() {
        this.$emit('click', this.info.id);
    }

    private copy() {
        this.$emit('copy', this.info.id);
    }

    private remove() {
        this.$emit('remove', this.info.id);
    }

    private tag() {
        this.$emit('tag', {
            id: this.info.id,
            tags: this.info.tags,
        });
    }
}
