





import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Canvas from '@/logics/domain/canvas/Canvas';
import Layer from '@/logics/domain/layer/Layer';
import Palette from '@/logics/domain/palette/Palette';
import { default as DrawCanvasStrategyFactory, StrategyType } from '@/logics/service/canvas/strategy/DrawCanvasStrategyFactory';

@Component
export default class LayerDrawer extends Vue {
    @Prop({ required: true }) private size!: number;
    @Prop({ required: true }) private layer!: Layer;
    @Prop({ required: true }) private palette!: Palette;
    private width: number = this.calcWidth(this.layer.width, this.layer.height);
    private height: number = this.calcHeight(this.layer.width, this.layer.height);
    private layerCanvas!: Canvas;
    private previewCanvas!: Canvas;

    public refresh() {
        // 裏でレイヤーを描画
        const strategy = DrawCanvasStrategyFactory.create(StrategyType.Redraw,
            this.layerCanvas, this.layer, this.palette);
        strategy.draw();
        // プレビューに表示
        this.previewCanvas.mappingFrom(this.layerCanvas);
    }

    public refreshSize(width: number, height: number) {
        this.width = this.calcWidth(width, height);
        this.height = this.calcHeight(width, height);
        this.layerCanvas.resize(width, height);
        this.previewCanvas.resize(this.width, this.height);
    }

    public getLayerCanvas() {
        return this.layerCanvas;
    }

    public getPreviewCanvas() {
        return this.previewCanvas;
    }

    @Watch('size')
    private sizeChanged() {
        this.refreshSize(this.layer.width, this.layer.height);
    }

    private mounted() {
        this.layerCanvas = new Canvas(this.layer.width, this.layer.height); // バックグラウンド描画
        this.previewCanvas = new Canvas(this.$refs.previewCanvas as HTMLCanvasElement);
    }

    private calcRatio(width: number, height: number) {
        return Math.min(width, height) / Math.max(width, height);
    }

    private calcWidth(width: number, height: number) {
        if (width < height) {
            return this.size * this.calcRatio(width, height);
        } else {
            return this.size;
        }
    }

    private calcHeight(width: number, height: number) {
        if (width > height) {
            return this.size * this.calcRatio(width, height);
        } else {
            return this.size;
        }
    }

}
