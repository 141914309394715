




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import LayerPreview from '@/components/organisms/layer/LayerPreview.vue';
import Layer from '@/logics/domain/layer/Layer';
import Palette from '@/logics/domain/palette/Palette';
import { LayerInfo } from '@/store/editor';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';
import Privilege from '@/logics/service/Privilege';

@Component({
    components: {
        LayerPreview,
    },
})
export default class LayerList extends Vue {

    @Watch('$store.state.editor.activeFrameIndex')
    @Watch('$store.state.editor.layerInfosMap')
    @Watch('$store.state.editor.colorPalette')
    public refresh() {
        this.$nextTick(() => {
            (this.$refs.layerPreviews as LayerPreview[]).forEach((p) => p.refresh());
        });
    }

    @Watch('$store.state.editor.width')
    @Watch('$store.state.editor.height')
    private sizeChanged() {
        this.previews.forEach((p) => p.refreshSize(
            this.$store.state.editor.width,
            this.$store.state.editor.height,
        ));
    }

    private get layerInfos(): LayerInfo[] {
        return this.$store.getters['editor/activeLayerInfos'];
    }

    private get palette(): Palette {
        return this.$store.state.editor.colorPalette;
    }

    private get activeLayerIndex() {
        return this.$store.state.editor.activeLayerIndex;
    }

    private get previews() {
        return this.$refs.layerPreviews as LayerPreview[];
    }

    private mounted() {
        this.previews[this.activeLayerIndex].activate();
    }

    /**
     * フレーム変更時は先頭を選択
     */
    @Watch('$store.state.editor.activeFrameIndex')
    private resetActiveLayer() {
        this.changeActiveLayer(0);
    }

    private changeActiveLayer(index: number) {
        // アクティブ要素の切り替え
        this.previews[this.activeLayerIndex].inactivate();
        this.previews[index].activate();
        this.$store.commit('editor/selectActiveLayerIndex', index);
    }

    /**
     * レイヤー階層を現在階層より上位にする
     */
    private frontLayer(index: number) {
        if (index <= 0) {
            return;
        }
        if (this.activeLayerIndex === index) {
            this.changeActiveLayer(index - 1);
        } else if (this.activeLayerIndex === index - 1) {
            this.changeActiveLayer(index);
        }
        this.$store.dispatch('editor/swapLayer', {
            index1: index,
            index2: index - 1,
        });
    }

    /**
     * レイヤー階層を現在階層より下位にする
     */
    private backLayer(index: number) {
        if (index >= this.layerInfos.length - 1) {
            return;
        }
        if (this.activeLayerIndex === index) {
            this.changeActiveLayer(index + 1);
        } else if (this.activeLayerIndex === index + 1) {
            this.changeActiveLayer(index);
        }
        this.$store.dispatch('editor/swapLayer', {
            index1: index,
            index2: index + 1,
        });
    }

    /**
     * レイヤーを削除する
     */
    private closeLayer(index: number) {
        // レイヤー全消しは禁止、最低一つ残す
        if (this.layerInfos.length <= 1) {
            return;
        }
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('editor.layerList.removeConfirm.title').toString(),
            message: this.$t('editor.layerList.removeConfirm.message').toString(),
            okCallback: () => {
                // アクティブレイヤーが末尾の場合は手前にずらす
                if (this.activeLayerIndex === this.layerInfos.length - 1) {
                    this.changeActiveLayer(this.activeLayerIndex - 1);
                }
                this.$store.dispatch('editor/deleteLayer', index);
            },
        }));
    }

    /**
     * レイヤーを複製する
     */
    private copyLayer(index: number) {
        Privilege.runWhenAllowAddLayer(() => {
            const info = this.layerInfos[index];
            this.$store.dispatch('editor/addLayer', info.layer.clone());
        }, this.layerInfos.length, 1, new GlobalMessage({
            title: this.$t('privilege.deniedCopyLayer.title').toString(),
            message: this.$t('privilege.deniedCopyLayer.message').toString(),
            okOnly: true,
        }));
    }

    /**
     * レイヤーを追加する
     */
    private addLayer() {
        Privilege.runWhenAllowAddLayer(() => {
            this.$store.dispatch('editor/addLayer');
        }, this.layerInfos.length, 1, new GlobalMessage({
            title: this.$t('privilege.deniedAddLayer.title').toString(),
            message: this.$t('privilege.deniedAddLayer.message').toString(),
            okOnly: true,
        }));
    }

    /**
     * レイヤーを非描画対象にする
     */
    private hideLayer(index: number) {
        this.$store.dispatch('editor/hideLayer', index);
    }

    /**
     * レイヤーを描画対象にする
     */
    private showLayer(index: number) {
        this.$store.dispatch('editor/showLayer', index);
    }
}
