// tslint:disable-next-line:no-var-requires
const GIFEncoder = require('gif-encoder-2');

export default class Gif {
    private encoder: any;

    public constructor(width: number, height: number, private fileName: string) {
        this.encoder = new GIFEncoder(width, height);
        this.encoder.start();
    }

    public addFrame(context: CanvasRenderingContext2D, delay: number) {
        this.encoder.setDelay(delay);
        this.encoder.addFrame(context);
    }

    public download() {
        this.encoder.finish();
        const data: Uint8Array = this.encoder.out.getData();
        const anchor = document.createElement('a') as HTMLAnchorElement;
        anchor.href = window.URL.createObjectURL(new Blob([data.buffer]));
        anchor.download = this.fileName;
        anchor.click();
    }
}
