import JsZip from 'jszip';

export default class Zip {
    private zip!: JsZip;

    public constructor(private zipFileName: string) {
        this.zip = new JsZip();
    }

    public addFile(fileName: string, data: any) {
        this.zip.file(fileName, data);
    }

    public async download() {
        const blob = await this.zip.generateAsync({ type: 'blob' });
        // ダウンロード
        const anchor = document.createElement('a') as HTMLAnchorElement;
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = this.zipFileName;
        anchor.click();
    }
}
