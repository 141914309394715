export default class ValidatorBuilder {
    private validators: Array<(value: any) => any> = [];

    public required(errorMessage: any) {
        this.validators.push((value: any) => !!value || errorMessage);
        return this;
    }

    public emailFormat(errorMessage: any) {
        this.validators.push((value: any) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || errorMessage);
        return this;
    }

    public length(length: number, errorMessage: any) {
        this.validators.push((value: string) => value.length <= length || errorMessage);
        return this;
    }

    public range(min: number, max: number, errorMessage: any) {
        this.validators.push((value: number) => min <= value && value <= max || errorMessage);
        return this;
    }

    public equals(other: any, errorMessage: any) {
        this.validators.push((value: any) => value === other || errorMessage);
        return this;
    }

    public password(errorMessage: any) {
        this.validators.push((value: string) => {
            return value.length >= 6
                && /[a-z]/.test(value)
                && /[A-Z]/.test(value)
                && /[0-9]/.test(value) || errorMessage;
        });
        return this;
    }

    public build() {
        return this.validators;
    }
}
