



































import { Vue, Component, Prop } from 'vue-property-decorator';
import ValidatorBuilder from '@/logics/util/ValidatorBuilder';
import SettlementButton from '@/components/organisms/account/SettlementButton.vue';
import { AccountType } from '@/store/account';

export interface FormResult {
    email: string;
}

@Component({
    components: {
        SettlementButton,
    },
})
export default class EditAccountForm extends Vue {
    @Prop({ default: '' }) private type!: string;
    @Prop({ default: '' }) private email!: string;
    private typeField = this.type === AccountType.Premium
        ? this.$t('account.plan.premium').toString()
        : this.$t('account.plan.free').toString();
    private emailField = this.email;

    private get emailRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required',
                { label: this.$t('form.editAccountForm.labels.mailAddress') }))
            .emailFormat(this.$t('errors.validation.malformed',
                { label: this.$t('form.editAccountForm.labels.mailAddress') }))
            .build();
    }

    private cancel() {
        this.typeField =  this.type === AccountType.Premium
            ? this.$t('account.plan.premium').toString()
            : this.$t('account.plan.free').toString();
        this.emailField  = this.email;
        this.$emit('cancel');
    }

    private submit() {
        const form = this.$refs.editAccountForm as any;
        if (!form.validate()) {
            return;
        }
        this.$emit('submit', {
            email: this.emailField,
        } as FormResult);
    }
}
