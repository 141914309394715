

























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { FrameInfo, LayerInfosMap, FrameImageRequestData } from '@/store/editor';
import FramePreview from '@/components/organisms/frame/FramePreview.vue';
import { FormResult as OutputImageRequestData, OutputType, AllFrameOutputWay } from '@/components/organisms/form/OutputImageForm.vue';
import Color from '@/logics/domain/palette/Color';
import Palette from '@/logics/domain/palette/Palette';
import Canvas from '@/logics/domain/canvas/Canvas';
import Zip from '@/logics/util/Zip';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';
import Privilege from '@/logics/service/Privilege';

@Component({
    components: {
        FramePreview,
    },
})
export default class FrameList extends Vue {

    @Watch('$store.state.editor.frameInfos')
    @Watch('$store.state.editor.colorPalette')
    public refresh() {
        this.$nextTick(() => {
            this.previews.forEach((p) => p.refresh());
        });
    }

    @Watch('$store.state.editor.width')
    @Watch('$store.state.editor.height')
    private sizeChanged() {
        this.previews.forEach((p) => p.refreshSize(
            this.$store.state.editor.width,
            this.$store.state.editor.height,
        ));
    }

    private get frameInfos(): FrameInfo[] {
        return this.$store.state.editor.frameInfos;
    }

    private get layerInfosMap(): LayerInfosMap {
        return this.$store.state.editor.layerInfosMap;
    }

    private get palette(): Palette {
        return this.$store.state.editor.colorPalette;
    }

    private get previews() {
        return this.$refs.framePreviews as FramePreview[];
    }

    private get activeFrameIndex() {
        return this.$store.state.editor.activeFrameIndex;
    }

    private mounted() {
        this.previews[this.activeFrameIndex].activate();
    }

    private changeActiveFrame(index: number) {
        // アクティブ要素の切り替え
        this.previews[this.activeFrameIndex].inactivate();
        this.previews[index].activate();
        this.$store.commit('editor/selectActiveFrameIndex', index);
    }

    /**
     * フレームを手前に移動
     */
    private frontFrame(index: number) {
        if (index <= 0) {
            return;
        }
        if (this.activeFrameIndex === index) {
            this.changeActiveFrame(index - 1);
        } else if (this.activeFrameIndex === index - 1) {
            this.changeActiveFrame(index);
        }
        this.$store.dispatch('editor/swapFrame', {
            index1: index,
            index2: index - 1,
        });
    }

    /**
     * フレームを後ろに移動
     */
    private backFrame(index: number) {
        if (index >= this.frameInfos.length - 1) {
            return;
        }
        if (this.activeFrameIndex === index) {
            this.changeActiveFrame(index + 1);
        } else if (this.activeFrameIndex === index + 1) {
            this.changeActiveFrame(index);
        }
        this.$store.dispatch('editor/swapFrame', {
            index1: index,
            index2: index + 1,
        });
    }

    /**
     * フレームを削除する
     */
    private closeFrame(index: number) {
        // フレーム全消しは禁止、最低一つ残す
        if (this.frameInfos.length <= 1) {
            return;
        }
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('editor.frameList.removeConfirm.title').toString(),
            message: this.$t('editor.frameList.removeConfirm.message').toString(),
            okCallback: () => {
                // アクティブフレームが末尾の場合は手前にずらす
                if (this.activeFrameIndex === this.frameInfos.length - 1) {
                    this.changeActiveFrame(this.activeFrameIndex - 1);
                }
                this.$store.dispatch('editor/deleteFrame', index);
            },
        }));
    }

    /**
     * フレームを複製する
     */
    private copyFrame(index: number) {
        const info = this.frameInfos[index];
        Privilege.runWhenAllowAddFrame(() => {
            this.$store.dispatch('editor/addFrame', {
                preview: info.layer.clone(),
                layers: this.layerInfosMap[info.id].map((layerInfo) => layerInfo.layer.clone()),
            });
        }, this.frameInfos.length , this.layerInfosMap[info.id].length, new GlobalMessage({
            title: this.$t('privilege.deniedCopyFrame.title').toString(),
            message: this.$t('privilege.deniedCopyFrame.message').toString(),
            okOnly: true,
        }));
    }

    /**
     * フレームの追加
     */
    private addFrame() {
        Privilege.runWhenAllowAddFrame(() => {
            this.$store.dispatch('editor/addFrame');
        }, this.frameInfos.length, 1, new GlobalMessage({
            title: this.$t('privilege.deniedAddFrame.title').toString(),
            message: this.$t('privilege.deniedAddFrame.message').toString(),
            okOnly: true,
        }));
    }

    /**
     * 画像の出力
     */
    @Watch('$store.state.editor.outputImageRequestData')
    private download() {
        const data: OutputImageRequestData = this.$store.state.editor.outputImageRequestData;
        const name = data.name;
        const backColor = data.isTransparent ? this.palette.getColor(0) : undefined;
        if (data.outputType === OutputType.CurrentFrame) {
            this.downloadCurrentFrame(`${name}.png`, backColor);
        } else if (data.allFrameOutputWay === AllFrameOutputWay.Concat) {
            this.downloadConcatAllFrame(`${name}.png`, backColor);
        } else {
            this.downloadAllFrame(name, backColor);
        }
    }

    private downloadCurrentFrame(fileName: string, backColor?: Color) {
        this.previews[this.activeFrameIndex]
            .getLayerCanvas().download(fileName, backColor);
    }

    private downloadConcatAllFrame(fileName: string, backColor?: Color) {
        const firstLayer = this.frameInfos[0].layer;
        const downloadCanvas = this.createWorkCanvas(
            firstLayer.width * this.frameInfos.length, firstLayer.height);
        let left = 0;
        this.previews.forEach((p) => {
            downloadCanvas.mappingFrom(p.getLayerCanvas(), left, 0);
            left += firstLayer.width;
        });
        downloadCanvas.download(fileName, backColor);
    }

    private async downloadAllFrame(name: string, backColor?: Color) {
        const zip = new Zip(`${name}.zip`);
        for (let i = 0; i < this.previews.length; i++) {
            const preview = this.previews[i];
            const data = await preview.getLayerCanvas().cloneForWork(backColor).toArrayBuffer();
            zip.addFile(`${name}_${i + 1}.png`, data);
        }
        zip.download();
    }

    private createWorkCanvas(width: number, height: number) {
        const canvasElement = document.createElement('canvas');
        canvasElement.width = width;
        canvasElement.height = height;
        return new Canvas(canvasElement);
    }

    /**
     * 画像データ文字列の通知
     */
    @Watch('$store.state.editor.frameImageRequestData')
    private notifyFrameImage() {
        const data = this.$store.state.editor.frameImageRequestData as FrameImageRequestData;
        data.callback(this.previews[data.frameIndex].getLayerCanvas().toPngDataUrl());
    }
}
