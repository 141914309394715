



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { default as Repository, SimpleNotice } from '@/logics/repository/api/NoticeApiRepository';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';

@Component
export default class NoticeList extends Vue {
    @Prop({default: () => []}) private notices!: SimpleNotice[];

    private async clickRow(id: string) {
        const notice = await Repository.get(id);
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: notice.title,
            message: notice.content,
            okOnly: true,
        }));
    }
}
