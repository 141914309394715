import { default as StoreData } from '@/logics/domain/palette/Palette';
import Api from './Api';
import { v4 as UUID } from 'uuid';

interface Palette { // サーバー側形式
    id: string;
    accountId: string;
    name: string;
    colors: Array<{ r: number, g: number, b: number, a: number }>;
}

interface SearchObject {
    accountId: string;
    keyword: string;
}

class PaletteApiRepository {
    private api = new Api<Palette>('palette');
    private searchApi = new Api<SearchObject>('palette/search');

    public async get(id: string) {
        const data = await this.api.get({
            id,
        });
        return this.convertToStoreData(data);
    }

    public async create(name: string, info: StoreData) {
        return this.api.post(this.convertToRestData(name, info, true));
    }

    public async update(name: string, info: StoreData) {
        return this.api.post(this.convertToRestData(name, info));
    }

    public async delete(id: string) {
        return this.api.delete({
            id,
        });
    }

    public async search(search: SearchObject) {
        const response = await this.searchApi.post<Palette[]>(search);
        return response.map((res) => this.convertToStoreData(res));
    }

    private convertToRestData(name: string, info: StoreData, isNew?: boolean): Palette {
        return {
            id: isNew ? UUID() : (info.id || UUID()),
            accountId: '',
            name,
            colors: info.getColors().map((color) => {
                return {
                    r: color.R,
                    g: color.G,
                    b: color.B,
                    a: color.A,
                };
            }),
        };
    }

    private convertToStoreData(data: Palette): StoreData {
        return new StoreData({
            id: data.id,
            name: data.name,
            data: data.colors.map((color) => {
                return {
                    color: {
                        r: color.r,
                        g: color.g,
                        b: color.b,
                        a: color.a,
                    },
                };
            }),
        });
    }
}
export default new PaletteApiRepository();
