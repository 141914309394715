














































import { Vue, Component, Prop } from 'vue-property-decorator';
import ValidatorBuilder from '@/logics/util/ValidatorBuilder';

export enum OutputType {
    CurrentFrame = 'CurrentFrame',
    AllFrame = 'AllFrame',
}

export enum AllFrameOutputWay {
    Concat = 'Concat',
    Split = 'Split',
}

export interface FormResult {
    name: string;
    outputType: string;
    isTransparent: boolean;
    allFrameOutputWay?: string;
}

@Component
export default class OutputImageFrom extends Vue {
    private OutputType = OutputType; // タグ内から参照するための定義
    private AllFrameOutputWay = AllFrameOutputWay;
    private nameField: string = this.$store.state.editor.name;
    private outputTypeField: string = OutputType.CurrentFrame;
    private allFrameOutputWay = AllFrameOutputWay.Concat;
    private isTransparentField = true;

    private get nameRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required',
                { label: this.$t('form.outputImageForm.labels.name') }))
            .build();
    }

    private get fileSuffix() {
        return this.outputTypeField === OutputType.AllFrame
            && this.allFrameOutputWay === AllFrameOutputWay.Split
            ? '.zip'
            : '.png';
    }

    private cancel() {
        this.$emit('cancel');
    }

    private submit() {
        const form = this.$refs.outputImageForm as any;
        if (!form.validate()) {
            return;
        }
        this.$emit('submit', {
            name: this.nameField,
            outputType: this.outputTypeField,
            isTransparent: this.isTransparentField,
            allFrameOutputWay: OutputType.AllFrame === this.outputTypeField
                ? this.allFrameOutputWay
                : undefined,
        } as FormResult);
    }
}
