import GlobalMouseEventHandler from './GlobalMouseEventHandler';

/**
 * ハンドラ引数
 */
export interface HandlerArguments {
    el: Element;
    onLeftButtonDown?: (e: MouseEvent) => void;
    onLeftButtonMove?: (e: MouseEvent) => void;
    onLeftButtonUp?: (e: MouseEvent) => void;
    onWheel?: (e: WheelEvent) => void;
    onWheelButtonDown?: (e: MouseEvent) => void;
    onWheelButtonMove?: (e: MouseEvent) => void;
    onWheelButtonUp?: (e: MouseEvent) => void;
    onRightButtonDown?: (e: MouseEvent) => void;
    onRightButtonMove?: (e: MouseEvent) => void;
    onRightButtonUp?: (e: MouseEvent) => void;
    onOther?: (e: MouseEvent) => void;
}

/**
 * マウスイベント処理用ハンドラ
 */
export default class MouseEventHandler {
    // ドラッグ状態の管理フラグ
    // ボタン押下状態のみの判定では、要素外押下から要素内に動いた場合にも処理が走るため、
    // 要素内からドラッグ開始した場合のみ処理が走るようにする
    private dragButtons = 0;
    private bindMousedown = this.onMouseDown.bind(this) as (e: Event) => void;
    private bindMousemove = this.onMouseMove.bind(this) as (e: Event) => void;
    private bindMouseup = this.onMouseUp.bind(this) as (e: Event) => void;
    private bindMouseWheel = this.onMouseWheel.bind(this) as (e: Event) => void;

    public constructor(private args: HandlerArguments) {
        this.args.el.addEventListener('mousedown', this.bindMousedown);
        this.args.el.addEventListener('mousemove', this.bindMousemove);
        this.args.el.addEventListener('mouseup', this.bindMouseup);
        this.args.el.addEventListener('wheel', this.bindMouseWheel);
        GlobalMouseEventHandler.addObserver(this.args.el, this.onOther.bind(this) as (e: Event) => void);
    }

    public releaseEvents() {
        this.args.el.removeEventListener('mousedown', this.bindMousedown);
        this.args.el.removeEventListener('mousemove', this.bindMousemove);
        this.args.el.removeEventListener('mouseup', this.bindMouseup);
        this.args.el.removeEventListener('wheel', this.bindMouseWheel);
    }

    private onMouseDown(e: MouseEvent) {
        if (this.dragButtons) {
            return;
        }
        this.dragButtons = e.buttons;

        if (this.isLeftButton(this.dragButtons) && this.args.onLeftButtonDown) {
            this.args.onLeftButtonDown(e);
        } else if (this.isWheelButton(this.dragButtons) && this.args.onWheelButtonDown) {
            this.args.onWheelButtonDown(e);
        } else if (this.isRightButton(this.dragButtons) && this.args.onRightButtonDown) {
            this.args.onRightButtonDown(e);
        }
    }

    private onMouseMove(e: MouseEvent) {
        // 要素外に出た後にボタンを離していても処理を継続する
        if (this.isLeftButton(this.dragButtons) && this.args.onLeftButtonMove) {
            this.args.onLeftButtonMove(e);
        } else if (this.isWheelButton(this.dragButtons) && this.args.onWheelButtonMove) {
            this.args.onWheelButtonMove(e);
        } else if (this.isRightButton(this.dragButtons) && this.args.onRightButtonMove) {
            this.args.onRightButtonMove(e);
        }
    }

    private onMouseUp(e: MouseEvent) {
        if (!this.dragButtons) {
            return;
        }

        if (this.isLeftButton(this.dragButtons) && this.args.onLeftButtonUp) {
            this.args.onLeftButtonUp(e);
        } else if (this.isWheelButton(this.dragButtons) && this.args.onWheelButtonUp) {
            this.args.onWheelButtonUp(e);
        } else if (this.isRightButton(this.dragButtons) && this.args.onRightButtonUp) {
            this.args.onRightButtonUp(e);
        }

        this.dragButtons = 0;
    }

    private onMouseWheel(e: WheelEvent) {
        if (this.args.onWheel) {
            this.args.onWheel(e);
        }
    }

    private onOther(e: MouseEvent) {
        if (this.args.onOther) {
            this.args.onOther(e);
        }
    }

    private isLeftButton(buttons: number) {
        return (buttons & 1) > 0;
    }

    private isWheelButton(buttons: number) {
        return (buttons & 4) > 0;
    }

    private isRightButton(buttons: number) {
        return (buttons & 2) > 0;
    }
}
