// bodyのマウスイベントを監視し、MouseEventHandler監視対象要素の範囲外イベントを通知する
// クラス自体のexportはしない（シングルトンとして利用）
class GlobalMouseEventHandler {
    private observers: Array<{ el: Element, notify: (e: MouseEvent) => void }> = [];

    public addObserver(el: Element, notify: (e: MouseEvent) => void) {
        this.observers.push({
            el,
            notify,
        });
    }

    /**
     * MouseEventHandlerへ監視対象外要素への押下処理であることを通知する
     * @param e マウスイベント
     */
    public notify(e: MouseEvent) {
        // DOMツリーから削除されているものは消しておく
        this.observers = this.observers.filter((observer) => {
            return document.body.contains(observer.el);
        });
        this.observers.forEach((observer) => {
            if (observer.el !== e.target) {
                observer.notify(e);
            }
        });
    }
}
const globalMouseEventHandler = new GlobalMouseEventHandler();

// bodyマウスイベントの監視
window.addEventListener('load', () => {
    document.body.addEventListener('mousedown', (e: MouseEvent) => {
        globalMouseEventHandler.notify(e as MouseEvent);
    });
});

export default globalMouseEventHandler;
