







import { Vue, Component, Prop } from 'vue-property-decorator';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';
import Settlement from '@/logics/service/Settlement';
import SettlementApiRepository from '@/logics/repository/api/SettlementApiRepository';
import GlobalError from '@/logics/domain/error/GlobalError';
import Privilege from '@/logics/service/Privilege';

@Component
export default class SettlementButton extends Vue {
    @Prop({ default: '' }) private email!: string;

    private get isPremium(): boolean {
        return Privilege.isPremium();
    }

    private get settlementCustomerId(): string {
        return this.$store.getters['account/getSettlementCustomerId'];
    }

    private get settlementSubscriptionId(): string {
        return this.$store.getters['account/getSettlementSubscriptionId'];
    }

    private async redirectSubscription() {
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('account.plan.toPremiumConfirm.title').toString(),
            message: this.$t('account.plan.toPremiumConfirm.message').toString(),
            okCallback: async () => {
                try {
                    this.$store.dispatch('startLoading');
                    const sessionId = await SettlementApiRepository.createSubscriptionCheckoutSession(
                        this.settlementCustomerId, this.email);
                    await Settlement.redirectToSettlement(sessionId);
                } catch (e) {
                    this.$store.dispatch('throwError',
                        new GlobalError(this.$t('errors.exception.settlement.redirect').toString()));
                } finally {
                    this.$store.dispatch('stopLoading');
                }
            },
        }));
    }

    private async redirectChangeCredit() {
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('account.plan.changeCreditConfirm.title').toString(),
            message: this.$t('account.plan.changeCreditConfirm.message').toString(),
            okCallback: async () => {
                try {
                    this.$store.dispatch('startLoading');
                    const sessionId = await SettlementApiRepository.createSetupCheckoutSession(
                        this.settlementCustomerId, this.settlementSubscriptionId);
                    await Settlement.redirectToSettlement(sessionId);
                } catch (e) {
                    this.$store.dispatch('throwError',
                        new GlobalError(this.$t('errors.exception.settlement.redirect').toString()));
                } finally {
                    this.$store.dispatch('stopLoading');
                }
            },
        }));
    }

    private async deleteSubscription() {
        this.$store.dispatch('showMessage', new GlobalMessage({
            title: this.$t('account.plan.toFreeConfirm.title').toString(),
            message: this.$t('account.plan.toFreeConfirm.message').toString(),
            okCallback: async () => {
                try {
                    this.$store.dispatch('startLoading');
                    await SettlementApiRepository.deleteSubscription(
                        this.settlementCustomerId, this.settlementSubscriptionId);
                    location.reload();
                } catch (e) {
                    this.$store.dispatch('throwError',
                        new GlobalError(this.$t('errors.exception.settlement.deleteSubscription').toString()));
                } finally {
                    this.$store.dispatch('stopLoading');
                }
            },
        }));
    }
}
