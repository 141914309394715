/**
 * 色モデル
 */
export default class Color {
    public static fromHex(hexColor: string) {
        const hex = hexColor.replace(/^#/, '');
        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);
        const temp = hex.slice(6, 8);
        let a = 255;
        if (!!temp) {
            a = parseInt(temp, 16);
        }
        return new Color(r, g, b, a);
    }

    public constructor(
        private r: number,
        private g: number,
        private b: number,
        private a: number) {
    }

    public get R() {
        return this.r;
    }

    public get G() {
        return this.g;
    }

    public get B() {
        return this.b;
    }

    public get A() {
        return this.a;
    }

    public toHex(withAlpha = false): string {
        const hexR = ('00' + this.r.toString(16)).slice(-2);
        const hexG = ('00' + this.g.toString(16)).slice(-2);
        const hexB = ('00' + this.b.toString(16)).slice(-2);
        const rgbHex = `#${hexR}${hexG}${hexB}`;
        if (!withAlpha) {
            return rgbHex;
        } else {
            // CSS4未対応ブラウザではアルファチャンネルは認識しない
            const hexA = ('00' + this.a.toString(16)).slice(-2);
            return `${rgbHex}${hexA}`;
        }
    }
}
