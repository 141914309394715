












import { Vue, Component, Prop } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';
import GlobalKeyEventHandler from '@/logics/util/handler/GlobalKeyEventHandler';
import { KeyCodes } from '@/logics/constants';
import Repository from '@/logics/repository/api/EditorApiRepository';
import { StoreData } from '@/store/editor';
import { AlertType, default as GlobalAlert } from '@/logics/domain/common/GlobalAlert';
import Privilege from '@/logics/service/Privilege';
import GlobalMessage from '@/logics/domain/common/GlobalMessage';

@Component({
    components: {
        CircleIconButton,
    },
})
export default class SaveButton extends Vue {
    private showOutputDialog = false;
    private src: string = require('@/assets/editor/save.svg');
    private backColor = '#e0e0e0';

    private mounted() {
        GlobalKeyEventHandler.addObserver(this.$refs.saveButton as Element, [KeyCodes.Ctrl, KeyCodes.S], () => {
            this.onclick();
        });
    }

    private get unsaved(): boolean {
        return this.$store.state.editor.unsaved;
    }

    private onmouseover() {
        this.backColor = '#b9ffc8';
    }

    private onmouseleave() {
        this.backColor = '#e0e0e0';
    }

    private async onclick() {
        Privilege.runWhenWithinLimit(() => {
            this.save();
        }, new GlobalMessage({
            title: this.$t('privilege.deniedSave.title').toString(),
            message: this.$t('privilege.deniedSave.message').toString(),
            okOnly: true,
        }));
    }

    private async save() {
        const state = this.$store.state.editor as StoreData;
        this.$store.dispatch('startLoading');
        this.$store.dispatch('editor/requestFrameImage', {
            frameIndex: 0,
            callback: async (base64Image: string)  => {
                try {
                    // サムネイル情報を更新する
                    this.$store.commit('editor/setThumbnail', base64Image);

                    const response = !state.registeredAt
                        ? await Repository.create(state)
                        : await Repository.update(state);
                    this.$store.commit('editor/setUpdateTimes', {
                        registeredAt: response.registeredAt,
                        updatedAt: response.updatedAt,
                    });
                    this.$store.commit('editor/changeUnsaved', false);
                    this.$store.dispatch('showAlert', new GlobalAlert(
                        AlertType.Success,
                        this.$t('editor.save.completeMessage').toString(),
                        2000));
                } finally {
                    this.$store.dispatch('stopLoading');
                }
            },
        });
    }
}
