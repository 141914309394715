import DrawStrategy from './DrawLayerStrategy';
import Layer from '../../../domain/layer/Layer';

/**
 * べた塗描画処理
 */
export default class DrawLayerBucketStrategy extends DrawStrategy {
    private origin!: Layer; // 描画開始初期レイヤー状態

    public constructor(layer: Layer, colorIndex: number) {
        super(layer, colorIndex);
    }

    public drawStart(x: number, y: number) {
        this.origin = this.layer.clone();
    }

    public drawMiddle(x: number, y: number) {
        // 不要
    }

    public drawEnd(x: number, y: number) {
        this.layer.overwriteFrom(this.origin);
        // 幅優先で塗りつぶしていく
        const queue = [];
        const memo = new Set<string>();
        const paintColor = this.layer.get(x, y);
        queue.push({ x, y });
        memo.add(this.createKey(x, y));
        while (queue.length > 0) {
            const current = queue.shift() as { x: number, y: number };
            this.layer.set(current.x, current.y, this.colorIndex);
            // 上
            if (this.canVisit(current.x, current.y - 1, paintColor, memo)) {
                queue.push({ x: current.x, y: current.y - 1 });
                memo.add(this.createKey(current.x, current.y - 1));
            }
            // 右
            if (this.canVisit(current.x + 1, current.y, paintColor, memo)) {
                queue.push({ x: current.x + 1, y: current.y });
                memo.add(this.createKey(current.x + 1, current.y ));
            }
            // 下
            if (this.canVisit(current.x, current.y + 1, paintColor, memo)) {
                queue.push({ x: current.x, y: current.y + 1 });
                memo.add(this.createKey(current.x, current.y + 1 ));
            }
            // 左
            if (this.canVisit(current.x - 1, current.y, paintColor, memo)) {
                queue.push({ x: current.x - 1, y: current.y });
                memo.add(this.createKey(current.x - 1, current.y ));
            }
        }
    }

    private createKey(x: number, y: number) {
        return `${x},${y}`;
    }

    private canVisit(x: number, y: number, paintColor: number, memo: Set<string>) {
        return !memo.has(this.createKey(x, y))
            && x >= 0 && x <= this.layer.width
            && y >= 0 && y <= this.layer.height
            && this.layer.get(x, y) === paintColor;
    }
}
