










import { Vue, Component } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';

@Component({
    components: {
        CircleIconButton,
    },
})
export default class MyPageButton extends Vue {
    private inquiryIcon = require('@/assets/header/inquiry.svg');

    private click() {
        window.open(process.env.VUE_APP_CONTACT_URL as string, '_blank');
    }
}
