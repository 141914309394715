import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

firebase.initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId:  process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
});
firebase.analytics();

export interface AuthInfo { // Firebase保持情報
    id: string;
    token: string;
    email: string;
    creationTime: string;
    lastSignInTime?: string;
}

class Auth {

    public async getUser() {
        const promise = new Promise<AuthInfo | null>((resolve) => {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (!user) {
                    resolve(null);
                } else {
                    resolve({
                        id: user!.uid,
                        token: await user!.getIdToken(),
                        email: user!.email!,
                        creationTime: this.utcStringToJstISOString(user!.metadata.creationTime!),
                        lastSignInTime: this.utcStringToJstISOString(user!.metadata.lastSignInTime!),
                    });
                }
            });
        });
        return promise;
    }

    public async getToken() {
        const user = await this.getUser();
        return user!.token;
    }

    public async login(email: string, password: string, rememberMe?: boolean) {
        if (rememberMe) {
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        } else {
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        }
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }

    public async logout() {
        return firebase.auth().signOut();
    }

    public async withdraw() {
        await firebase.auth().currentUser!.delete();
    }

    public async addAccount(email: string, password: string) {
        const res = await firebase.auth().createUserWithEmailAndPassword(email, password);
        res.user!.sendEmailVerification();
        return this.utcStringToJstISOString(res.user!.metadata.creationTime!);
    }

    public async sendResetPasswordEmail(email: string) {
        return firebase.auth().sendPasswordResetEmail(email);
    }

    public async updateEmail(email: string) {
        const user = firebase.auth().currentUser;
        await user!.updateEmail(email);
        return user!.sendEmailVerification();
    }

    private utcStringToJstISOString(utc: string) {
        const date = new Date(utc);
        date.setTime(date.getTime() + 1000 * 3600 * 9);
        return date.toISOString();
    }
}
export default new Auth();
