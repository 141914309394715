




























import { Vue, Component, Prop } from 'vue-property-decorator';
import CircleIconButton from '@/components/molecules/CircleIconButton.vue';

@Component({
    components: {
        CircleIconButton,
    },
})
export default class ListItemHoverMenu extends Vue {
    private srcCopy = require('@/assets/layer/copy.svg');
    private srcRemove = require('@/assets/layer/close.svg');
    private srcTag = require('@/assets/layer/tag.svg');

    private onclickBody() {
        this.$emit('body');
    }

    private onclickCopy(e: MouseEvent) {
        this.$emit('copy');
    }

    private onclickRemove(e: MouseEvent) {
        this.$emit('remove');
    }

    private onclickTag(e: MouseEvent) {
        this.$emit('tag');
    }
}
