import DrawStrategy from './DrawLayerStrategy';
import PenStrategy from './DrawLayerPenStrategy';
import Layer from '../../../domain/layer/Layer';

/**
 * 消しゴム描画処理
 */
export default class DrawLayerEraserStrategy extends DrawStrategy {
    private strategy: PenStrategy;

    public constructor(layer: Layer) {
        super(layer, 0);
        this.strategy = new PenStrategy(this.layer, 0);
    }

    public drawStart(x: number, y: number) {
        this.strategy.drawStart(x, y);
    }

    public drawMiddle(x: number, y: number) {
        this.strategy.drawMiddle(x, y);
    }

    public drawEnd(x: number, y: number) {
        this.strategy.drawEnd(x, y);
    }
}
