import DrawStrategy from './DrawLayerStrategy';
import Layer from '../../../domain/layer/Layer';

export default class DrawLayerRegionMoveStrategy extends DrawStrategy {
    private origin: Layer;
    private pileUpLayer!: Layer;

    private isFlipHolizontal = false;
    private isFlipVertical = false;
    private lastDx = 0;
    private lastDy = 0;

    public constructor(
            layer: Layer,
            private left: number,
            private top: number,
            private width: number,
            private height: number,
            private clipLayer?: Layer) {
        super(layer, 0);
        this.origin = this.layer.clone();
        this.drawMiddle(0, 0); // 初期pileUpLayerを設定する
    }

    public drawStart(x: number, y: number) {
        // 不要
    }

    public drawMiddle(dx: number, dy: number) {
        this.transcribe(dx, dy);
    }

    public drawEnd(x: number, y: number) {
        // 不要
    }

    /**
     * 切り抜き領域のダミーレイヤーを返す
     */
    public getPileUpLayer() {
        return this.pileUpLayer;
    }

    /**
     * 選択領域を一時的に削除する（drawMoveが呼ばれると再描画されるので使用直後に選択領域の解除を想定）
     */
    public temporaryDelete() {
        this.layer.overwriteFrom(this.origin);
        const copyLayer = this.clipLayer || this.layer;
        const right = Math.min(this.left + this.width - 1, copyLayer.width - 1);
        const bottom = Math.min(this.top + this.height - 1, copyLayer.height - 1);
        this.pileUpLayer.clear();
        for (let y = this.top; y <= bottom; y++) {
            for (let x = this.left; x <= right; x++) {
                if (!this.clipLayer) {
                    this.layer.set(x, y, 0);
                }
            }
        }
        this.layer.pileUp(this.pileUpLayer);
    }

    public flipHorizontal() {
        this.isFlipHolizontal = !this.isFlipHolizontal;
        this.transcribe(this.lastDx, this.lastDy);
    }

    public flipVertical() {
        this.isFlipVertical = !this.isFlipVertical;
        this.transcribe(this.lastDx, this.lastDy);
    }

    private transcribe(dx: number, dy: number) {
        this.layer.overwriteFrom(this.origin);
        const copyLayer = this.clipLayer || this.layer;
        const right = Math.min(this.left + this.width - 1, copyLayer.width - 1);
        const bottom = Math.min(this.top + this.height - 1, copyLayer.height - 1);
        this.pileUpLayer = new Layer(copyLayer.width, copyLayer.height);
        for (let y = this.top; y <= bottom; y++) {
            for (let x = this.left; x <= right; x++) {
                // 抜き出し部の色を転写
                if (!this.pileUpLayer.isOut(x, y)) {
                    this.pileUpLayer.set(x, y, copyLayer.get(x, y));
                }
                if (!this.clipLayer) {
                    // 直に元のレイヤーから切り抜く場合は抜き出し元の色はクリア
                    // （別のレイヤー（clipLayer）を元レイヤーに被せる場合はクリアしない）
                    this.layer.set(x, y, 0);
                }
            }
        }
        if (this.isFlipHolizontal) {
            // 左右反転
            this.pileUpLayer.flipHorizontal(this.left, this.top, right, bottom);
        }
        if (this.isFlipVertical) {
            // 上下反転
            this.pileUpLayer.flipVertical(this.left, this.top, right, bottom);
        }
        // 差分移動
        this.pileUpLayer.shift(dx, dy);
        // 重ねる
        this.layer.pileUp(this.pileUpLayer);
        this.lastDx = dx;
        this.lastDy = dy;
    }
}
