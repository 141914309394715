



import { Vue, Component, Prop } from 'vue-property-decorator';
import IconImage from '@/components/molecules/IconImage.vue';

@Component({
    components: {
        IconImage,
    },
})
export default class SquareIconButton extends Vue {
    @Prop({ required: true }) protected src!: string;
    @Prop({ default: 32 }) private width!: number;
    @Prop({ default: 32 }) private height!: number;
    @Prop({ default: undefined }) private color!: string;

    private onclick(e: MouseEvent) {
        this.$emit('click', e);
    }
}
