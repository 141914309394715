









import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Canvas from '@/logics/domain/canvas/Canvas';
import Layer from '@/logics/domain/layer/Layer';
import Palette from '@/logics/domain/palette/Palette';
import MouseEventHandler from '@/logics/util/handler/MouseEventHandler';
import { LayerInfo } from '@/store/editor';
import { default as DrawCanvasStrategyFactory, StrategyType as CanvasStrategyType } from '@/logics/service/canvas/strategy/DrawCanvasStrategyFactory';
import { default as DrawLayerStrategyFactory, StrategyType as LayerStrategyType, StrategyType, StrategyObserver } from '@/logics/service/layer/strategy/DrawLayerStrategyFactory';
import DrawLayerSelectRegionStrategy, { StrategyObserver as SelectObserver } from '@/logics/service/layer/strategy/DrawLayerSelectRegionStrategy';
import DrawLayerRegionMoveStrategy from '@/logics/service/layer/strategy/DrawLayerRegionMoveStrategy';
import DrawLayerStrategy from '@/logics/service/layer/strategy/DrawLayerStrategy';
import SelectRegion from '@/components/organisms/editor/SelectRegion.vue';
import GlobalKeyEventHandler from '@/logics/util/handler/GlobalKeyEventHandler';
import EditorStateLocalRepository from '@/logics/repository/local/EditorStateLocalRepository';
import { KeyCodes } from '@/logics/constants';

@Component({
    components: {
        SelectRegion,
    },
})
export default class PixelCanvas extends Vue {
    @Prop({ required: true }) private realWidth!: number;
    @Prop({ required: true }) private realHeight!: number;
    @Prop({ required: true }) private scale!: number;
    private width = this.realWidth;
    private height = this.realHeight;
    private realCanvas!: Canvas;
    private fakeCanvas!: Canvas;
    private gridCanvas!: Canvas;
    private auxiliaryGridCanvas!: Canvas;
    private gridData = '';
    private auxiliaryGridData = '';
    private currentStrategy?: DrawLayerStrategy;
    private selectRegion = {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        show: false,
    };
    private mouseEventHandler!: MouseEventHandler;
    private strategyObserver!: StrategyObserver;
    private beforeLayer!: Layer; // アンドゥ用

    private get syncRefresh(): number {
        return this.$store.state.editor.syncRefresh;
    }

    private get colorIndex(): number {
        return this.$store.state.editor.colorIndex;
    }

    private get colorPalette(): Palette {
        return this.$store.state.editor.colorPalette;
    }

    private get layerInfos(): LayerInfo[] {
        return this.$store.getters['editor/activeLayerInfos'];
    }

    private get activeLayer(): Layer {
        return this.$store.getters['editor/activeLayer'];
    }

    private get fakeWidth() {
        return this.width * this.scale;
    }

    private get fakeHeight() {
        return this.height * this.scale;
    }

    private get gridWidth() {
        return this.fakeWidth;
    }

    private get gridHeight() {
        return this.fakeHeight;
    }

    private mounted() {
        this.realCanvas = new Canvas(this.width, this.height); // バックグラウンド描画
        this.gridCanvas = new Canvas(this.gridWidth, this.gridHeight); // バックグラウンド描画
        this.auxiliaryGridCanvas = new Canvas(this.gridWidth, this.gridHeight); // バックグラウンド描画
        this.fakeCanvas = new Canvas(this.$refs.fake as HTMLCanvasElement);
        this.toolChanged();
        this.refreshCanvas();
        this.refreshGrid();
        this.refreshAuxiliaryGrid();
        this.$store.commit('editor/forceRefreshAllFrameInfos');

        /** Strategyオブザーバ */
        this.strategyObserver = {
            // 選択色変更
            requestChangeColorIndex: (newIndex) => {
                this.$store.commit('editor/selectColorIndex', newIndex);
            },
            // 範囲選択
            requestSelectRegion: (x, y, width, height, clipLayer?) => {
                this.beforeLayer = this.activeLayer.clone();
                this.selectRegion.left = x;
                this.selectRegion.top = y;
                this.selectRegion.width = width;
                this.selectRegion.height = height;
                this.selectRegion.show = true;
                this.changeStrategy(new DrawLayerRegionMoveStrategy(this.activeLayer, x, y, width, height, clipLayer));
            },
        };

        // マウスイベント処理
        this.mouseEventHandler = new MouseEventHandler({
            el: this.$refs.fake as Element,
            // 左クリック系
            onLeftButtonDown: (e) => {
                if (!(this.currentStrategy instanceof DrawLayerRegionMoveStrategy)) {
                    // DrawLayerRegionMoveStrategyのbeforeLayer設定はrequestSelectRegionで行う
                    this.beforeLayer = this.activeLayer.clone();
                }
                const realPosition = this.calcRealPosition(e);
                this.currentStrategy!.drawStart(realPosition.x, realPosition.y);
                this.refreshCanvas(true);
            },
            onLeftButtonMove: (e) => {
                const realPosition = this.calcRealPosition(e);
                this.currentStrategy!.drawMiddle(realPosition.x, realPosition.y);
                this.refreshCanvas(true);
            },
            onLeftButtonUp: (e) => {
                const realPosition = this.calcRealPosition(e);
                this.currentStrategy!.drawEnd(realPosition.x, realPosition.y);
                this.refreshCanvas(true);
                if (this.currentStrategy!.necessaryUndo() && !this.beforeLayer.equals(this.activeLayer)) {
                    this.$store.dispatch('editor/addUndo', this.beforeLayer);
                }
                // 更新が同期させない時は、マウスアップ時に更新を通知する
                if (!this.syncRefresh) {
                    requestAnimationFrame(() => {
                        this.notifyRefresh(this.createPileUpLayer(), true);
                    });
                }
            },
            // 右クリック系
            onRightButtonUp: (e) => {
                if (this.currentStrategy instanceof DrawLayerSelectRegionStrategy
                    || this.currentStrategy instanceof DrawLayerRegionMoveStrategy) {
                    return;
                }
                // スポイト
                const realPosition = this.calcRealPosition(e);
                DrawLayerStrategyFactory.create(
                    LayerStrategyType.Syringe,
                    this.activeLayer,
                    this.colorIndex,
                    this.strategyObserver)
                    .drawEnd(realPosition.x, realPosition.y);
            },
            // ホイール（拡大・縮小）
            onWheel: (e) => {
                if (e.deltaY !== 0) {
                    this.$emit('changeScale', e.deltaY > 0 ? -1 : 1);
                }
            },
        });
        // キーイベント処理
        // 全選択
        GlobalKeyEventHandler.addObserver(this.$refs.pixelCanvas as Element, [KeyCodes.Ctrl, KeyCodes.A], () => {
            (this.strategyObserver as SelectObserver)
                .requestSelectRegion(0, 0, this.activeLayer.width, this.activeLayer.height);
        });
        // コピー（選択領域のみ）
        GlobalKeyEventHandler.addObserver(this.$refs.pixelCanvas as Element, [KeyCodes.Ctrl, KeyCodes.C], () => {
            if (!(this.currentStrategy instanceof DrawLayerRegionMoveStrategy)) {
                return;
            }
            EditorStateLocalRepository.saveTemporaryLayer({
                region: {
                    left: this.selectRegion.left,
                    top: this.selectRegion.top,
                    width: this.selectRegion.width,
                    height: this.selectRegion.height,
                },
                layer: this.currentStrategy.getPileUpLayer().clone(),
            });
        });
        // 切り取り（選択領域のみ）
        GlobalKeyEventHandler.addObserver(this.$refs.pixelCanvas as Element, [KeyCodes.Ctrl, KeyCodes.X], () => {
            if (!(this.currentStrategy instanceof DrawLayerRegionMoveStrategy)) {
                return;
            }
            EditorStateLocalRepository.saveTemporaryLayer({
                region: {
                    left: this.selectRegion.left,
                    top: this.selectRegion.top,
                    width: this.selectRegion.width,
                    height: this.selectRegion.height,
                },
                layer: this.currentStrategy.getPileUpLayer().clone(),
            });
            this.currentStrategy.temporaryDelete();
            this.commitSelectRegion();
            this.refreshCanvas(true);
        });
        // 削除（選択範囲のみ）
        GlobalKeyEventHandler.addObserver(this.$refs.pixelCanvas as Element, [KeyCodes.Delete], () => {
            if (!(this.currentStrategy instanceof DrawLayerRegionMoveStrategy)) {
                return;
            }
            this.currentStrategy.temporaryDelete();
            this.commitSelectRegion();
            this.refreshCanvas(true);
        });
        // 貼り付け（選択領域のみ）
        GlobalKeyEventHandler.addObserver(this.$refs.pixelCanvas as Element, [KeyCodes.Ctrl, KeyCodes.V], async () => {
            const data = await EditorStateLocalRepository.loadTemporaryLayer();
            if (!data) {
                return;
            }
            if (this.currentStrategy instanceof DrawLayerRegionMoveStrategy) {
                this.commitSelectRegion();
            }
            // commitSelectRegion直後に範囲選択処理を呼ぶと挙動がおかしくなるので、$nextTickを挟む
            this.$nextTick(() => {
                // 左上に貼り付ける
                data.layer.shift(-data.region.left, -data.region.top);
                (this.strategyObserver as SelectObserver)
                    .requestSelectRegion(0, 0,
                        data.region.width, data.region.height, data.layer);
                this.refreshCanvas(true);
            });
        });
        // 反転（上下）
        GlobalKeyEventHandler.addObserver(this.$refs.pixelCanvas as Element, [KeyCodes.Up], async () => {
            if (!(this.currentStrategy instanceof DrawLayerRegionMoveStrategy)) {
                return;
            }
            this.currentStrategy.flipVertical();
            this.refreshCanvas(true);
        });
        GlobalKeyEventHandler.addObserver(this.$refs.pixelCanvas as Element, [KeyCodes.Down], async () => {
            if (!(this.currentStrategy instanceof DrawLayerRegionMoveStrategy)) {
                return;
            }
            this.currentStrategy.flipVertical();
            this.refreshCanvas(true);
        });
        // 反転（左右）
        GlobalKeyEventHandler.addObserver(this.$refs.pixelCanvas as Element, [KeyCodes.Left], async () => {
            if (!(this.currentStrategy instanceof DrawLayerRegionMoveStrategy)) {
                return;
            }
            this.currentStrategy.flipHorizontal();
            this.refreshCanvas(true);
        });
        GlobalKeyEventHandler.addObserver(this.$refs.pixelCanvas as Element, [KeyCodes.Right], async () => {
            if (!(this.currentStrategy instanceof DrawLayerRegionMoveStrategy)) {
                return;
            }
            this.currentStrategy.flipHorizontal();
            this.refreshCanvas(true);
        });
    }

    /**
     * currentStrategyの変更
     * オブザーバー内から変更する場合は、thisフィールドを直接変更できないためメソッドを通す
     */
    private changeStrategy(strategy: DrawLayerStrategy) {
        this.currentStrategy = strategy;
    }

    @Watch('scale')
    private scaleChanged() {
        // 再描画
        this.$nextTick(() => {
            // サイズ変更によるデータ領域再構築
            this.gridCanvas.resize(this.gridWidth, this.gridHeight);
            this.auxiliaryGridCanvas.resize(this.gridWidth, this.gridHeight);
            this.refreshCanvas();
            this.refreshGrid();
            this.refreshAuxiliaryGrid();
        });
    }

    @Watch('$store.state.editor.width')
    @Watch('$store.state.editor.height')
    private sizeChanged() {
        this.width = this.$store.state.editor.width;
        this.height = this.$store.state.editor.height;
        this.realCanvas.resize(this.width, this.height);
        this.scaleChanged();
    }

    @Watch('$store.state.editor.colorIndex')
    private colorChanged() {
        this.currentStrategy!.changeColorIndex(this.colorIndex);
    }

    @Watch('$store.state.editor.colorPalette')
    private paletteChanged() {
        // 色変更の着色
        this.refreshCanvas();
    }

    @Watch('$store.state.editor.activeLayerIndex')
    @Watch('$store.state.editor.activeFrameIndex')
    private activeLayerChanged() {
        // ツールの描画対象レイヤーの再設定
        this.currentStrategy!.changeLayer(this.activeLayer);
    }

    @Watch('$store.state.editor.activeFrameIndex')
    @Watch('$store.state.editor.layerInfosMap')
    private layerChanged() {
        // ツールの描画対象レイヤーの再設定
        this.currentStrategy!.changeLayer(this.activeLayer);
        this.refreshCanvas();
    }

    @Watch('$store.state.editor.activeToolType')
    private toolChanged() {
        this.currentStrategy = DrawLayerStrategyFactory.create(this.$store.state.editor.activeToolType,
            this.activeLayer, this.colorIndex, this.strategyObserver);
    }

    @Watch('$store.state.editor.auxiliaryGridSize')
    private auxiliaryGridSizeChanged() {
        this.refreshAuxiliaryGrid();
    }

    /**
     * 選択領域のドラッグ
     */
    private dragSelectRegion(e: { x: number, y: number }) {
        this.currentStrategy!.drawMiddle(e.x, e.y);
        this.refreshCanvas(true);
    }

    /**
     * 選択領域の確定
     */
    private commitSelectRegion() {
        this.selectRegion.show = false;
        this.toolChanged(); // 範囲選択Strategyに戻す
        if (!this.beforeLayer.equals(this.activeLayer)) {
            this.$store.dispatch('editor/addUndo', this.beforeLayer);
        }
        // 更新が同期させない時は、選択確定時に更新を通知する
        if (!this.syncRefresh) {
            requestAnimationFrame(() => {
                this.notifyRefresh(this.createPileUpLayer(), true);
            });
        }
    }

    /**
     * レイヤー群から描画用レイヤーを生成する
     */
    private createPileUpLayer() {
        const drawLayer = new Layer(this.width, this.height);
        this.layerInfos.filter((info) => info.isDraw).reverse().forEach((info) => {
            drawLayer.pileUp(info.layer);
        });
        return drawLayer;
    }

    /**
     * マウス座標から実際のキャンバス上の座標を計算する
     */
    private calcRealPosition(e: MouseEvent) {
        const ratio =  1 / this.scale;
        const realMapX = Math.floor(e.offsetX * ratio);
        const realMapY = Math.floor(e.offsetY * ratio);
        return {
            x: realMapX,
            y: realMapY,
        };
    }

    /**
     * 実描画キャンバスを表示用キャンバスに転写する。
     */
    private mappingRealToFake() {
        this.fakeCanvas.mappingFrom(this.realCanvas);
    }

    /**
     * グリッドの描画
     */
    private refreshGrid() {
        const strategy = DrawCanvasStrategyFactory.create(CanvasStrategyType.Grid, this.gridCanvas);
        strategy.draw(this.scale);
        this.gridData = this.gridCanvas.toPngDataUrl();
    }

    private refreshAuxiliaryGrid() {
        if (!this.$store.state.editor.auxiliaryGridSize) {
            this.auxiliaryGridData = '';
            return;
        }
        const strategy = DrawCanvasStrategyFactory.create(
            CanvasStrategyType.AuxiliaryGrid, this.auxiliaryGridCanvas, this.$store.state.editor.auxiliaryGridSize);
        strategy.draw(this.scale);
        this.auxiliaryGridData = this.auxiliaryGridCanvas.toPngDataUrl();
    }

    private refreshCanvas(refreshLayer = false) {
        requestAnimationFrame(() => {
            const pileUpLayer = this.createPileUpLayer();
            const strategy = DrawCanvasStrategyFactory.create(CanvasStrategyType.Redraw,
                this.realCanvas, pileUpLayer, this.colorPalette);
            strategy.draw();
            this.mappingRealToFake();
            if (this.syncRefresh) {
                this.notifyRefresh(pileUpLayer, refreshLayer);
            }
            this.$store.commit('editor/changeUnsaved', true);
        });
    }

    private notifyRefresh(pileUpLayer: Layer, refreshLayer = false) {
        if (refreshLayer) { // レイヤーを更新した場合のみ連携する（自分のWatchと無限ループにならないようにする）
            this.$store.commit('editor/refreshLayerInfosMap');
        }
        this.$store.commit('editor/refreshFrameInfos',
            { index: this.$store.state.editor.activeFrameIndex, layer: pileUpLayer });
    }
}
