import Vue from 'vue';
import { Module } from 'vuex';
import { v4 as UUID } from 'uuid';
import Layer from '@/logics/domain/layer/Layer';
import Palette from '@/logics/domain/palette/Palette';
import { StrategyType } from '@/logics/service/layer/strategy/DrawLayerStrategyFactory';
import UndoRedoManager from '@/logics/util/manager/UndoRedoManager';
import OutputImageRequestData from '@/components/organisms/form/OutputImageFrom.vue';

export interface LayerInfo {
    id: string;
    isDraw: boolean;
    layer: Layer;
}

export interface FrameInfo {
    id: string;
    layer: Layer;
}

export interface LayerInfosMap {
    [uuid: string]: LayerInfo[];
}

export interface UndoRedoData {
    frameId: string;
    layerId: string;
    layer: Layer;
}

export interface FrameImageRequestData {
    frameIndex: number;
    callback: (base64Image: string) => void;
}

/**
 * 永続化対象データ
 */
export interface StoreData {
    id: string;
    name: string;
    width: number;
    height: number;
    colorPalette: Palette;
    layerInfosMap: LayerInfosMap;
    frameInfos: FrameInfo[];
    registeredAt?: string;
    updatedAt?: string;
    additional: {
        thumbnail?: string;
        tags?: string[];
    };
}

export type EditorState = StoreData & {
    unsaved: boolean;
    showGrid: boolean;
    auxiliaryGridSize: number;
    syncRefresh: boolean;
    colorIndex: number;
    activeToolType: StrategyType;
    activeLayerIndex: number;
    activeFrameIndex: number;
    unredo: UndoRedoManager<UndoRedoData>;
    // 画像出力要求
    outputImageRequestData: OutputImageRequestData;
    // 画像文字列要求
    frameImageRequestData: FrameImageRequestData;
    asCopy: boolean;
};

const EditorModule: Module<EditorState, any> = {
    namespaced: true,
    state: {
        // initializeで初期化するが、ここにプロパティを設定しておかないとオブザーバが機能しない
        id: '',
        name: '',
        width: 0,
        height: 0,
        unsaved: false,
        showGrid: true,
        auxiliaryGridSize: 0,
        syncRefresh: true,
        colorIndex: 0,
        colorPalette: {} as Palette,
        activeToolType: StrategyType.Pen,
        activeLayerIndex: 0,
        activeFrameIndex: 0,
        layerInfosMap: {},
        frameInfos: [],
        additional: {},
        unredo: {} as UndoRedoManager<UndoRedoData>,
        outputImageRequestData: {} as OutputImageRequestData,
        frameImageRequestData: {} as FrameImageRequestData,
        asCopy: false,
    } as EditorState,
    getters: {
        size: (state) => {
            return {
                width: state.width,
                height: state.height,
            };
        },
        activeLayerInfos: (state, getters) => {
            return state.layerInfosMap[getters.activeFrameId];
        },
        activeLayerInfo: (state, getters) => {
            return getters.activeLayerInfos[state.activeLayerIndex] || getters.activeLayerInfos[0];
        },
        activeLayer: (state, getters) => {
            return getters.activeLayerInfo.layer;
        },
        activeFrameInfo: (state) => {
            return state.frameInfos[state.activeFrameIndex];
        },
        activeFrameId: (state, getters) => {
            return getters.activeFrameInfo.id;
        },
        canUndo: (state) => {
            return state.unredo.canUndo();
        },
        canRedo: (state) => {
            return state.unredo.canRedo();
        },
        totalLayerDotsCount: (state) => () => { // キャッシュさせないため関数として返す
            return state.width * state.height;
        },
        totalLayerCount: (state) => () => { // キャッシュさせないため関数として返す
            let totalLayerCount = 0;
            for (const key in state.layerInfosMap) {
                if (!state.layerInfosMap[key]) {
                    continue;
                }
                totalLayerCount += state.layerInfosMap[key].length;
            }
            return totalLayerCount;
        },
        frameCount: (state) => () => {
            return state.frameInfos.length;
        },
    },
    mutations: {
        initialize: (state, args: EditorState) => {
            state.id = args.id || UUID();
            state.name = args.name || '';
            state.width = args.width || 1;
            state.height = args.height || 1;
            state.unsaved = !!args.unsaved;
            state.showGrid = !!args.showGrid;
            state.auxiliaryGridSize = args.auxiliaryGridSize || 0;
            state.syncRefresh = !!args.syncRefresh;
            state.colorIndex = args.colorIndex || 0;
            state.colorPalette = args.colorPalette || new Palette();
            state.activeToolType = args.activeToolType || StrategyType.Pen;
            state.activeLayerIndex = args.activeLayerIndex || 0;
            state.activeFrameIndex = args.activeFrameIndex || 0;
            state.registeredAt = args.registeredAt;
            state.updatedAt = args.updatedAt;
            const initFrameId = UUID();
            state.layerInfosMap = args.layerInfosMap || {
                [initFrameId]: [
                    { id: UUID(), isDraw: true, layer: new Layer(state.width, state.height) },
                ] as LayerInfo[],
            };
            state.frameInfos = args.frameInfos || [
                { id: initFrameId, layer: new Layer(state.width, state.height) },
            ] as FrameInfo[];
            state.unredo = args.unredo || new UndoRedoManager<UndoRedoData>();
            state.additional.tags = args.additional && args.additional.tags || undefined;
            state.additional.thumbnail = args.additional && args.additional.thumbnail || undefined;

            // 複製時はID置き換え
            if (args.asCopy) {
                // エディタID置き換え
                state.id = UUID();
                // フレームID置き換え
                state.frameInfos.forEach((frame) => {
                    const newId = UUID();
                    state.layerInfosMap[newId] = state.layerInfosMap[frame.id];
                    delete state.layerInfosMap[frame.id];
                    frame.id = newId;
                });
                // レイヤーID置き換え
                for (const key in state.layerInfosMap) {
                    if (state.layerInfosMap[key]) {
                        state.layerInfosMap[key].forEach((layer) => {
                            layer.id = UUID();
                        });
                    }
                }
            }
        },
        changeState: (state, data: { name: string, width: number, height: number, tagIds: string[] }) => {
            state.name = data.name;
            state.width = data.width;
            state.height = data.height;
            state.additional.tags = data.tagIds;
        },
        changeUnsaved: (state, unsaved: boolean) => {
            state.unsaved = unsaved;
        },
        changeShowGrid: (state, showGrid: boolean) => {
            state.showGrid = showGrid;
        },
        changeAuxiliaryGridSize: (state, auxiliaryGridSize: number) => {
            state.auxiliaryGridSize = auxiliaryGridSize;
        },
        changeSyncRefresh: (state, syncRefresh: boolean) => {
            state.syncRefresh = syncRefresh;
        },
        selectColorIndex: (state, colorIndex) => {
            state.colorIndex = colorIndex;
        },
        selectTool: (state, toolType: StrategyType) => {
            state.activeToolType = toolType;
        },
        changePalette: (state, colorPalette) => {
            state.colorPalette = colorPalette;
        },
        changePaletteColor: (state, args) => {
            const palette = state.colorPalette;
            palette.changeColor(args.index, args.color);
            // オブジェクト内部の変更は変更イベントが発火しないので一旦nullにして入れなおす
            state.colorPalette = null as any;
            state.colorPalette = palette;
        },
        selectActiveLayerIndex: (state, layerIndex) => {
            state.activeLayerIndex = layerIndex;
        },
        selectActiveFrameIndex: (state, frameIndex) => {
            state.activeFrameIndex = frameIndex;
        },
        refreshLayerInfosMap: (state) => {
            // layerInfosMap更新通知用空更新
            // オブジェクト内部の変更は変更イベントが発火しないので一旦nullにして入れなおす
            const layerInfosMap = state.layerInfosMap;
            state.layerInfosMap = null as any;
            state.layerInfosMap = layerInfosMap;
        },
        refreshFrameInfos: (state, args?: { index: number, layer: Layer }) => {
            if (args) {
                const currentFrame: FrameInfo = state.frameInfos[args.index];
                currentFrame.layer = args.layer;
                Vue.set(state.frameInfos, args.index, currentFrame);
            } else {
                // frameInfos更新通知用空更新
                Vue.set(state.frameInfos, 0, state.frameInfos[0]);
            }
        },
        forceRefreshAllFrameInfos: (state) => {
                // 強制再描画
                state.frameInfos.forEach((frameInfo, index) => {
                    const layerInfos = state.layerInfosMap[frameInfo.id];
                    const newLayer = new Layer(layerInfos[0].layer.width, layerInfos[0].layer.height);
                    layerInfos.filter((layerInfo) => layerInfo.isDraw).reverse().forEach((layerInfo) => {
                        newLayer.pileUp(layerInfo.layer);
                    });
                    frameInfo.layer = newLayer;
                    Vue.set(state.frameInfos, index, frameInfo);
                });
        },
        refreshUndoRedo: (state) => {
            // アンドゥリドゥ管理更新通知
            // オブジェクト内部の変更は変更イベントが発火しないので一旦nullにして入れなおす
            const manager = state.unredo;
            state.unredo = null as any;
            state.unredo = manager;
        },
        setOutputImageRequestData: (state, args: OutputImageRequestData) => {
            state.outputImageRequestData = args;
        },
        setFrameImageRequestData: (state, args: FrameImageRequestData) => {
            state.frameImageRequestData = args;
        },
        setUpdateTimes(state, times: { registeredAt?: string, updatedAt?: string }) {
            state.registeredAt = times.registeredAt;
            state.updatedAt = times.updatedAt;
        },
        setTags(state, tagIds: string[]) {
            state.additional.tags = tagIds;
        },
        setThumbnail(state, imageString: string) {
            state.additional.thumbnail = imageString;
        },
    },
    actions: {
        // レイヤー系
        swapLayer: (context, args) => {
            const layerInfos = context.getters.activeLayerInfos;
            const layerInfo = layerInfos[args.index1];
            layerInfos[args.index1] = layerInfos[args.index2];
            layerInfos[args.index2] = layerInfo;
            context.commit('refreshLayerInfosMap');
        },
        addLayer: (context, newLayer?: Layer) => {
            const layer = context.getters.activeLayer;
            context.getters.activeLayerInfos.push({
                id: UUID(),
                isDraw: true,
                layer: newLayer || new Layer(layer.width, layer.height),
            });
            context.commit('refreshLayerInfosMap');
        },
        resizeLayer: (context, args: { width: number, height: number }) => {
            Object.keys(context.state.layerInfosMap).forEach((key) => {
                context.state.layerInfosMap[key].forEach((layerInfo) => {
                    layerInfo.layer.resize(args.width, args.height);
                });
            });
            context.state.frameInfos.forEach((frameInfo) => {
                frameInfo.layer.resize(args.width, args.height);
            });
            context.commit('refreshLayerInfosMap');
            context.commit('forceRefreshAllFrameInfos');
        },
        deleteLayer: (context, index) => {
            context.getters.activeLayerInfos.splice(index, 1);
            context.commit('refreshLayerInfosMap');
        },
        hideLayer: (context, index) => {
            context.getters.activeLayerInfos[index].isDraw = false;
            context.commit('refreshLayerInfosMap');
        },
        showLayer: (context, index) => {
            context.getters.activeLayerInfos[index].isDraw = true;
            context.commit('refreshLayerInfosMap');
        },
        // フレーム系
        swapFrame: (context, args) => {
            const frameInfos = context.state.frameInfos;
            const frameInfo = frameInfos[args.index1];
            frameInfos[args.index1] = frameInfos[args.index2];
            frameInfos[args.index2] = frameInfo;
            context.commit('refreshLayerInfosMap');
        },
        addFrame: (context, args?: { preview: Layer, layers: Layer[] }) => {
            const layer = context.state.frameInfos[0].layer;
            const frameUuid = UUID();
            context.state.frameInfos.push({
                id: frameUuid,
                layer: args ? args.preview : new Layer(layer.width, layer.height),
            });
            // 初期レイヤーの追加
            context.state.layerInfosMap[frameUuid] = args
                ? args.layers.map((l: Layer) => {
                    return {
                        id: UUID(),
                        isDraw: true,
                        layer: l,
                    };
                })
                : [{
                    id: UUID(),
                    isDraw: true,
                    layer: new Layer(layer.width, layer.height),
                }];
            context.commit('refreshFrameInfos');
        },
        deleteFrame: (context, index) => {
            const deleted = context.state.frameInfos.splice(index, 1);
            // 紐づくレイヤー情報の削除
            delete context.state.layerInfosMap[deleted[0].id];
            context.commit('refreshFrameInfos');
        },
        // アンドゥリドゥ
        addUndo: (context, layer: Layer) => {
            const manager = context.state.unredo;
            const frameInfo = context.getters.activeFrameInfo as FrameInfo;
            const layerInfo = context.getters.activeLayerInfo as LayerInfo;
            manager.addUndo({
                frameId: frameInfo.id,
                layerId: layerInfo.id,
                layer,
            });
            manager.clearRedo();
            context.commit('refreshUndoRedo');
        },
        undo: (context) => {
            const manager = context.state.unredo;
            while (manager.canUndo()) {
                const data = manager.undo();
                const layerInfos = context.state.layerInfosMap[data.frameId] || [];
                const layerInfo = layerInfos.filter((info) => info.id === data.layerId);
                if (layerInfo.length > 0) {
                    const redoLayer = layerInfo[0].layer.clone();
                    layerInfo[0].layer.overwriteFrom(data.layer);
                    manager.addRedo({
                        frameId: data.frameId,
                        layerId: data.layerId,
                        layer: redoLayer,
                    });
                    context.commit('refreshUndoRedo');
                    context.commit('refreshLayerInfosMap');
                    context.commit('forceRefreshAllFrameInfos');
                    break;
                }
            }
        },
        redo: (context) => {
            const manager = context.state.unredo;
            while (manager.canRedo()) {
                const data = manager.redo();
                const layerInfos = context.state.layerInfosMap[data.frameId] || [];
                const layerInfo = layerInfos.filter((info) => info.id === data.layerId);
                if (layerInfo.length > 0) {
                    const undoLayer = layerInfo[0].layer.clone();
                    layerInfo[0].layer.overwriteFrom(data.layer);
                    manager.addUndo({
                        frameId: data.frameId,
                        layerId: data.layerId,
                        layer: undoLayer,
                    });
                    context.commit('refreshUndoRedo');
                    context.commit('refreshLayerInfosMap');
                    context.commit('forceRefreshAllFrameInfos');
                    break;
                }
            }
        },
        // フレーム管理コンポーネントに対してのダウンロード処理要求
        requestOutputImage: (context, data: OutputImageRequestData) => {
            context.commit('setOutputImageRequestData', data);
        },
        requestFrameImage: (context, data: FrameImageRequestData) => {
            context.commit('setFrameImageRequestData', data);
        },
    },
};

export default EditorModule;
