


























































import { Vue, Component, Prop } from 'vue-property-decorator';
import ValidatorBuilder from '@/logics/util/ValidatorBuilder';
import Privilege from '@/logics/service/Privilege';
import TagList from '@/components/organisms/tag/TagList.vue';
import TagApiRepository, { Tag } from '@/logics/repository/api/TagApiRepository';
import SettingTagForm, { FormResult as SettingTagParams } from '@/components/organisms/form/SettingTagForm.vue';

export interface FormResult {
    name: string;
    width: number;
    height: number;
    tagIds: string[];
}

@Component({
    components: {
        TagList,
        SettingTagForm,
    },
})
export default class SettingCanvasForm extends Vue {
    @Prop({ default: '' }) private name!: string;
    @Prop({ default: '32' }) private width!: string;
    @Prop({ default: '32' }) private height!: string;
    @Prop({ default: () => [] }) private tagIds!: string[];
    private nameField: string = this.name;
    private widthField: string = this.width;
    private heightField: string = this.height;
    private tagField: Tag[] = [];
    private showEditTagDialog = false;

    private get nameRules() {
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required', { label: this.$t('form.settingCanvasForm.labels.name') }))
            .length(100, this.$t('errors.validation.length', {
                    label: this.$t('form.settingCanvasForm.labels.name'),
                    length: 100,
                }))
            .build();
    }

    private get widthRules() {
        const setting = Privilege.getPrivilegeSetting();
        const widthLimit = setting.imageSizeLimit.width;
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required', { label: this.$t('form.settingCanvasForm.labels.width') }))
            .range(1, widthLimit, this.$t('errors.validation.range', {
                    label: this.$t('form.settingCanvasForm.labels.width'),
                    min: '1px',
                    max: `${widthLimit}px`,
                }))
            .build();
    }

    private get heightRules() {
        const setting = Privilege.getPrivilegeSetting();
        const heightLimit = setting.imageSizeLimit.height;
        return new ValidatorBuilder()
            .required(this.$t('errors.validation.required', { label: this.$t('form.settingCanvasForm.labels.height') }))
            .range(1, heightLimit, this.$t('errors.validation.range', {
                    label: this.$t('form.settingCanvasForm.labels.height'),
                    min: '1px',
                    max: `${heightLimit}px`,
                }))
            .build();
    }

    private get tagFieldIds(): string[] {
        return this.tagField.map((tag: Tag) => tag.id);
    }

    private async mounted() {
        this.tagField = await TagApiRepository.many(this.tagIds);
    }

    private cancel() {
        this.nameField = this.name;
        this.widthField = this.width;
        this.heightField = this.height;
        this.$emit('cancel');
    }

    private submit() {
        const form = this.$refs.settingCanvasForm as any;
        if (!form.validate()) {
            return;
        }
        this.$emit('submit', {
            name: this.nameField,
            width: +this.widthField,
            height: +this.heightField,
            tagIds: this.tagField.map((tag) => tag.id),
        } as FormResult);
    }

    private submitEditTag(params: SettingTagParams) {
        this.tagField = params.tags;
        this.showEditTagDialog = false;
    }
}
