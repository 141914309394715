export enum AlertType {
    Success = 'success',
    Information = 'info',
    Warning = 'warning',
    Error = 'error',
}

export default class GlobalAlert {
    public constructor(
        public readonly type: AlertType,
        public readonly message: string,
        public readonly closeTime: number | null = null /* ms */) {}
}
