



















import { Vue, Component, Prop } from 'vue-property-decorator';
import ToolBox from '@/components/templates/ToolBox.vue';
import EditorBoard from '@/components/templates/EditorBoard.vue';
import PaletteBoard from '@/components/templates/PaletteBoard.vue';
import LayerBoard from '@/components/templates/LayerBoard.vue';
import FrameBoard from '@/components/templates/FrameBoard.vue';
import LocalRepository, { LocalStoreData } from '@/logics/repository/local/EditorStateLocalRepository';
import ApiRepository from '@/logics/repository/api/EditorApiRepository';
import GlobalError from '@/logics/domain/error/GlobalError';
import { EditorState } from '@/store/editor';

@Component({
    components: {
        ToolBox,
        EditorBoard,
        PaletteBoard,
        LayerBoard,
        FrameBoard,
    },
})
export default class EditorView extends Vue {
    @Prop() private id!: string;
    @Prop() private name!: string;
    @Prop() private width!: string;
    @Prop() private height!: string;
    @Prop() private tagIds!: string; // JSON配列文字列
    @Prop({ default: null }) private asCopy!: string; // ルーター制約上、string型だが有効時は文字列としてtureが来る
    private complete = false;
    // イベントリスナーのthisでクラスのメソッドにうまくアクセスできないので、
    // フィールドとして関数を持たせる
    private saveTemporaryMethod = () => {
        LocalRepository.save({
            id: this.$store.state.editor.id,
            unsaved: this.$store.state.editor.unsaved,
            name: this.$store.state.editor.name,
            width: this.$store.state.editor.width,
            height: this.$store.state.editor.height,
            colorPalette: this.$store.state.editor.colorPalette,
            layerInfosMap: this.$store.state.editor.layerInfosMap,
            frameInfos: this.$store.state.editor.frameInfos,
            additional: this.$store.state.editor.additional,
        });
        // 描画設定
        LocalRepository.saveTemporarySetting({
            showGrid: this.$store.state.editor.showGrid,
            syncRefresh: this.$store.state.editor.syncRefresh,
        });
    }

    private created() {
        this.initialize();
        window.addEventListener('beforeunload', this.saveTemporaryMethod);
        this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('beforeunload', this.saveTemporaryMethod);
        });
    }

    private async initialize() {
        try {
            this.$store.dispatch('startLoading');
            let params: Partial<EditorState & LocalStoreData> | null;
            if (this.name) {
                const tagIds = JSON.parse(this.tagIds);
                // 新規作成
                params = {
                    name: this.name,
                    width: +this.width,
                    height: +this.height,
                    additional: {
                        tags: tagIds,
                    },
                };
            } else if (this.id) {
                // サーバーから取得
                params = await ApiRepository.get(this.id);
                Object.assign(params, {
                    asCopy: !!this.asCopy,
                });
            } else {
                // ローカルストレージから復元
                params = await LocalRepository.load();
            }
            // 前回の設定状態を復元
            const setting = await LocalRepository.loadTemporarySetting();
            this.$store.commit('editor/initialize', Object.assign(params, setting));
            this.complete = true;

            setTimeout(() => {
                // 初期化時のレイヤー描画で未保存フラグが立つので、初期化処理後に設定し直す
                if (params) {
                    this.$store.commit('editor/changeUnsaved', !!params.unsaved);
                }
            }, 0);
        } catch (e) {
            this.$store.dispatch('throwError', new GlobalError(
                this.$t('errors.exception.loadEditData').toString(),
                () => { this.$router.push({ name: 'mypage' }); },
            ));
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }

    private beforeDestroy() {
        this.saveTemporaryMethod();
    }
}
