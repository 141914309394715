































import { Vue, Component } from 'vue-property-decorator';
import PaletteBoardMenu from '@/components/organisms/palette/PaletteBoardMenu.vue';
import PaletteButton from '@/components/organisms/palette/PaletteButton.vue';
import Palette from '@/logics/domain/palette/Palette';
import PaletteApiRepository from '@/logics/repository/api/PaletteApiRepository';

@Component({
    components: {
        PaletteBoardMenu,
        PaletteButton,
    },
})
export default class PaletteBoard extends Vue {
    private colorsUpdator = true;

    private get colorPalette() {
        return this.$store.state.editor.colorPalette;
    }

    private selectColor(index: number) {
        this.$store.commit('editor/selectColorIndex', index);
    }

    private changeColor(index: number, color: string) {
        this.$store.commit('editor/changePaletteColor', {
            index,
            color,
        });
    }

    /**
     * 色一覧の強制更新
     * （v-forが更新されないので一旦削除して再描画する）
     */
    private updateColors() {
        this.colorsUpdator = false;
        this.$nextTick(() => {
            this.colorsUpdator = true;
        });
    }

    private async savePalette(name: string) {
        try {
            this.$store.dispatch('startLoading');
            await PaletteApiRepository.create(name, this.colorPalette);
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }

    private async loadPalette(palette: Palette) {
        try {
            this.$store.dispatch('startLoading');
            this.$store.commit('editor/changePalette', palette);
            this.updateColors();
        } finally {
            this.$store.dispatch('stopLoading');
        }
    }
}
